import React, { useState, useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography
} from '@material-ui/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RefreshIcon from '@material-ui/icons/Refresh';
import TabletMacIcon from '@material-ui/icons/TabletMac';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const dummyData = [
  {"tier": {"code": "pro", "name": "Pro"}, "count": 35},
  {"tier": {"code": "trial", "name": "Trial"}, "count": 15},
  {"tier": {"code": "basic", "name": "Basic"}, "count": 23}
];


const ActiveTiers = props => {
  
  const theme = useTheme();
  const classes = useStyles();
  const graphRef = useRef();


  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      //intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };
  const PACKAGES = [
    {
      title: 'Trial',
      icon: <PhoneIphoneIcon />,
      color: theme.palette.primary.main
    },
    {
      title: 'Basic',
      icon: <TabletMacIcon />,
      color: theme.palette.error.main
    },
    {
      title: 'Advanced',
      icon: <PhoneIphoneIcon />,
      color: theme.palette.warning.main
    },
    {
      title: 'Pro',
      icon: <TabletMacIcon />,
      color: theme.palette.error.main
    },
    {
      title: 'Enterprise',
      icon: <PhoneIphoneIcon />,
      color: theme.palette.warning.main
    }
  ];

  const { className, tierCounters, loading, ...rest } = props,
        [tierCounts, setTierCounts] = useState([]),
        //[total, setTotal] = useState(0),
        [packages, setPackages] = useState([]),
        [data] = useState({});

  
  useEffect(() => {
    const tierCountsT = tierCounters?tierCounters:dummyData;
    /*console.log("useEffect:tierCountsT,tierCounts", tierCountsT, tierCounts);*/
    if (tierCountsT !== tierCounts){
    setTierCounts(tierCountsT);
    
    const totalT = tierCountsT.reduce((prev, curr) => prev + curr.count, 0);
    //setTotal(totalT);
    /*console.log("useEffect:total", total);*/
    const packagesT = PACKAGES.filter((pckg) => {
      return !!tierCountsT.find((tierCountP) => tierCountP.tier.name === pckg.title);
    }).map((pckg) => {
      const pCount = tierCountsT.find((tierCountP) => tierCountP.tier.name === pckg.title).count;
      return {
        
        title: pckg.title,
        value: Math.round(pCount / totalT * 100),
        count: pCount,
        icon: pckg.icon,
        color: pckg.color
      }
    });
    /*const packagesT = tierCountsT.map((item) => {
      const packageDefinition = PACKAGES.filter((pckg) => pckg.title === item.tier.name)[0];
      return {
        title: packageDefinition.title,
        value: Math.round(item.count / totalT * 100),
        icon: packageDefinition.icon,
        color: packageDefinition.color
      }
    });*/
    const dataT = {
      datasets: [
        {
          data: packagesT.map((item) => item.count),
          backgroundColor: packagesT.map((item) => item.color),
          borderWidth: 8,
          borderColor: theme.palette.white,
          hoverBorderColor: theme.palette.white
        }
      ],
      labels: packagesT.map((item) => item.title),
    }

    setPackages(packagesT);
    
    /*setTotal(tierCounts.reduce((prev, curr) => prev + curr.count, 0));
    setPackages(tierCounts.map((item) => {
      const packageDefinition = PACKAGES.filter((pckg) => pckg.title === item.tier.name)[0];
      return {
        title: packageDefinition.title,
        value: Math.round(item.count / total * 100),
        icon: packageDefinition.icon,
        color: packageDefinition.color
      }
    }));
    setData({
      datasets: [
        {
          data: tierCounts.map((item) => item.count),
          backgroundColor: [
            theme.palette.primary.main,
            theme.palette.error.main,
            theme.palette.warning.main
          ],
          borderWidth: 8,
          borderColor: theme.palette.white,
          hoverBorderColor: theme.palette.white
        }
      ],
      labels: tierCounts.map((item) => item.tier.name),
    });*/

    /*console.log("useEffect:tierCounters", tierCounters);
    console.log("useEffect:tierCounts", tierCountsT);
    console.log("useEffect:data", dataT);*/
    if(graphRef.current){
      graphRef.current.chartInstance.data = dataT;
      graphRef.current.chartInstance.update({
          preservation: true,
      });
    }}
  }, [tierCounters, tierCounts, PACKAGES, theme.palette.white]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <IconButton size="small">
            <RefreshIcon />
          </IconButton>
        }
        title="Active tiers"
      />
      <Divider />
      <CardContent className={loading?"loading":""}>
        <div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
            ref={graphRef}
          />
        </div>
        <div className={classes.stats}>
          {packages.map(pckg => (
            <div
              className={classes.device}
              key={pckg.title}
            >
              {/*<span className={classes.deviceIcon}>{pckg.icon}</span>*/}
              <Typography variant="body1">{pckg.title}</Typography>
              <Typography
                style={{ color: pckg.color }}
                variant="h3"
              >
                {pckg.value}%
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

ActiveTiers.propTypes = {
  className: PropTypes.string
};

export default ActiveTiers;
