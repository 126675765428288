import { useEffect } from "react";
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import PropTypes from "prop-types";
//import Auth from "services/auth";
import { USER_STATUS_LOGGED_IN, USER_STATUS_LOGGED_OUT } from "common/session";
import { useAuth } from "common/auth";
import { Auth, } from 'aws-amplify';

const ExternalSignIn = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { authenticationStatus, getAuthenticationStatus } = useAuth();

  useEffect(() => {
    async function getAuth() {
      const auth = await getAuthenticationStatus();
      if (auth === USER_STATUS_LOGGED_IN) {
        navigate("/dashboard");
      }
      else {
        if (auth === USER_STATUS_LOGGED_OUT) {
          Auth.federatedSignIn();
        }
      }
    }
    getAuth();
    /*if (searchParams && searchParams.has("code")) {
      session.auth.dispatcher({
        type: ACTION_LOADING_USER_INFORMATION
      });
    }
    else {
      if (auth === USER_STATUS_LOGGED_IN) {
        navigate("/dashboard");
      }
      else {
        if (auth === USER_STATUS_LOGGED_OUT) {
          Auth.federatedSignIn();
        }
      }
    }*/
  }, [searchParams, getAuthenticationStatus, navigate, authenticationStatus]);

  return "";
};

ExternalSignIn.propTypes = {
  history: PropTypes.object,
};

export default ExternalSignIn;
