import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { axiosInstance } from "common/network";
import { Box, Stack } from "@mui/material";
import { Modal, Button, Typography, Divider } from "@material-ui/core";
import { formatDate } from "helpers/formaters";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  title: {
    // color: 'red'
  },
  divider: {
    height: "4px",
    backgroundColor: theme.palette.primary.main,
  },
  editButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "#66ee98",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
  },
  calendarButton: {
    width: "32px",
    height: "32px",
  },
  expiredDateModalTitle: {
    paddingTop: "12px",
    paddingBottom: "18px",
  },
  expiredDateModalDescription: {
    paddingTop: "50px",
    paddingBottom: "40px",
    fontSize: "18px",
    fontWeight: "normal",
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    padding: "8px 8px",
    maxWidth: "220px",
    width: "100%",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "8px 8px",
    maxWidth: "220px",
    width: "100%",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  tierTitle: {
    fontSize: "18px",
    fontWeight: "normal",
    paddingBottom: "4px",
  },
  pointText: {
    fontSize: "18px",
    fontWeight: "normal",
    textAlign: "center",
  },
  styleExpiredDate: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    backgroundColor: theme.palette.primary.contrastText,
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "8px",
    paddingBottom: "6px",
  },
  inputContainer: {
    // display: { sm: "flex", xs: "block" },
    display: "flex",
    // flexDirection: { sm: "row", xs: "column" }
  },
  dropdown: {
    height: "56px",
    backgroundColor: "#f2f2f2",
    paddingLeft: "16px",
    paddingRight: "16px",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "normal",
  },
  dateInput: {
    display: "flex",
    height: "56px",
    backgroundColor: "#f2f2f2",
    paddingLeft: "16px",
    paddingRight: "16px",
    borderRadius: "8px",
    borderWidth: 0,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 0,
  },
}));

const UpdateExpireDateModal = (props) => {
  const { accountUuid, packageUuid } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleConfirm = () => {
    // requst api PUT: https://testing-api.didimo.co/v3/accounts/{accountUuid}/packages
    const body = {
      package_uuid: packageUuid,
      expiry_date: moment(new Date(props?.selectedDate)).format("YYYY-MM-DD"),
    };

    const url =
      process.env.REACT_APP_API_ROOT + `v3/accounts/${accountUuid}/packages`;
    console.log("API PUT: ", url);
    console.log("------- body ------", body);

    try {
      axiosInstance.put(url, body).then((response) => {
        console.log("update expiration date success: ", response);
        props.handleSuccess();
        handleClose();
      }).catch = (e) => {
        console.log("update expiration date error:", e);
        handleClose();
      };
    } catch (err) {
      console.log("update expiration date error:", err);
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: { lg: "55%", xs: "50%" },
          transform: "translate(-50%, -50%)",
          width: { sm: "70%", xs: "85%" },
          backgroundColor: "white",
          border: "1px solid #00000040",
          boxShadow: 24,
          borderRadius: "2px",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "8px",
          paddingBottom: "6px",
        }}
      >
        <Typography
          id="modal-modal-title"
          className={classes.expiredDateModalTitle}
          variant="h4"
        >
          Update expired date
        </Typography>
        <Divider className={classes.divider} />

        <Typography
          id="modal-modal-description"
          className={classes.expiredDateModalDescription}
          variant="h5"
        >
          This action will update the expired date to{" "}
          {formatDate(props?.selectedDate)}. Are you sure you want to continue?
        </Typography>

        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            paddingTop: "12px",
            paddingBottom: "40px",
            flexDirection: { sm: "row", xs: "row" },
          }}
          gap={3}
        >
          <Button
            className={classes.cancelButton}
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            className={classes.confirmButton}
            color="primary"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UpdateExpireDateModal;
