import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "helpers/formaters";
import { withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const styles = () => ({
    root: {
        whiteSpace: "nowrap"
    },
    symbol: {
      color: "grey"
    },
    notAvailable: {
      color: "rgba(0,0,0,0.4)",
      fontStyle: "italic"
    },
});

const Currency = (props) => {
  const { value, onEmptyValueElement, component, classes, ...rest } = props;

  let Component = component || "span";
  if (!value)  {
    if (onEmptyValueElement) {
        return onEmptyValueElement;
    }
    else {
        if (onEmptyValueElement === undefined) {
            return <Typography variant="body1" className={classes.notAvailable}>N/A</Typography>;
        }
    }
  }
  return (
    <Component className={classes.root} {...rest}><span className={classes.symbol}>$</span>{formatNumber(value)}</Component>
  );
};

Currency.propTypes = {
  component: PropTypes.string,
  value: PropTypes.any,
  ifNullValue: PropTypes.any,
};

export default withStyles( styles )(Currency);
