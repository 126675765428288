import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, allowIf, ...rest } = props;

  let isAllowed = null;

  if (allowIf) {
    isAllowed = allowIf();
  }
  else {
    isAllowed = true;
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        (isAllowed) ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Navigate to="/profile/login" />
        )
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

function mapStateToProps(state) {
  return { session: state.session, profile: state.profile };
}

export default connect(mapStateToProps)(RouteWithLayout);
