export default () => (
    [
        {
            "id": 1,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "fbx",
            "label": "FBX",
            "type": "target",
            "group": "targets",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"fbx\", \"type\": \"target\", \"group\": \"targets\", \"label\": \"FBX\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 2,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "gltf",
            "label": "gLTF",
            "type": "target",
            "group": "targets",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"gltf\", \"type\": \"target\", \"group\": \"targets\", \"label\": \"gLTF\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 3,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "lod0",
            "label": "LOD0-fake",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"lod0\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD0\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 4,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "lod1",
            "label": "LOD1",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"lod1\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD1\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 5,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "lod2",
            "label": "LOD2",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"lod2\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD2\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 6,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "lod3",
            "label": "LOD3",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"lod3\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD3\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 7,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "lod4",
            "label": "LOD4",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 4,
            "data": "{\"name\": \"lod4\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD4\", \"order\": 4, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 8,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "lod5",
            "label": "LOD5",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 5,
            "data": "{\"name\": \"lod5\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD5\", \"order\": 5, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 9,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "lod6",
            "label": "LOD6",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 6,
            "data": "{\"name\": \"lod6\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD6\", \"order\": 6, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 10,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "lod7",
            "label": "LOD7",
            "type": "capability",
            "group": "lodspec",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 7,
            "data": "{\"name\": \"lod7\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD7\", \"order\": 7, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 11,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "drest",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 5,
            "data": "{\"name\": \"drest\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 5, \"points\": 0, \"exclude\": [\"unity\", \"webviewer\", \"basic\", \"expressions\", \"visemes\"], \"processing_points\": 0}"
        },
        {
            "id": 12,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "photo",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"photo\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 13,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "iphone",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 4,
            "data": "{\"name\": \"iphone\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 4, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 14,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "tex_1k",
            "label": "1K",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"tex_1k\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"1K\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 15,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "tex_2k",
            "label": "2K",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"tex_2k\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"2K\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 16,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "tex_4k",
            "label": "4K",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"tex_4k\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"4K\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 17,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "no_body",
            "label": "None",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"no_body\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"None\", \"order\": 0, \"points\": 0, \"exclude\": [\"full_body\"], \"processing_points\": 0}"
        },
        {
            "id": 18,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "tex_512",
            "label": "512",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"tex_512\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"512\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 19,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "rig_full",
            "label": "Full B'shapes + Visemes",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"rig_full\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"Full B'shapes + Visemes\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 20,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "rig_none",
            "label": "None",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"rig_none\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"None\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 21,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "full_body",
            "label": "Full Body",
            "type": "capability",
            "group": "head_body",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"full_body\", \"type\": \"capability\", \"group\": \"head_body\", \"label\": \"Full Body\", \"order\": 0, \"points\": 0, \"comment\": \"should be with-body\", \"processing_points\": 0}"
        },
        {
            "id": 22,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "head_only",
            "label": "Head Only",
            "type": "capability",
            "group": "head_body",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"head_only\", \"type\": \"capability\", \"group\": \"head_body\", \"label\": \"Head Only\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 23,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "multishot",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"multishot\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 24,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "pre_sized",
            "label": "Pre Sized",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"pre_sized\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"Pre Sized\", \"order\": 1, \"points\": 0, \"exclude\": [\"head_only\"], \"processing_points\": 0}"
        },
        {
            "id": 25,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "rig_arkit",
            "label": "ARKit B'shapes",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"rig_arkit\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"ARKit B'shapes\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 26,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "photo_depth",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"photo_depth\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 27,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "rig_limited",
            "label": "Limited B'shapes",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"rig_limited\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"Limited B'shapes\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 28,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "measurements",
            "label": "Measurements",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"measurements\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"Measurements\", \"order\": 2, \"points\": 0, \"exclude\": [\"head_only\"], \"processing_points\": 0}"
        },
        {
            "id": 29,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "extras_vellus",
            "label": "Vellus",
            "type": "capability",
            "group": "extras",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"extras_vellus\", \"type\": \"capability\", \"group\": \"extras\", \"label\": \"Vellus\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 30,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "body_from_photo",
            "label": "Photo",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"body_from_photo\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"Photo\", \"order\": 3, \"points\": 0, \"exclude\": [\"head_only\"], \"processing_points\": 0}"
        },
        {
            "id": 31,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "extras_bloodmaps",
            "label": "Blood maps",
            "type": "capability",
            "group": "extras",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"extras_bloodmaps\", \"type\": \"capability\", \"group\": \"extras\", \"label\": \"Blood maps\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 32,
            "pricing_model_id": 1,
            "model_name": "default_v3",
            "pipeline_version": "develop",
            "name": "extras_wrinklemaps",
            "label": "Wrinkle maps",
            "type": "capability",
            "group": "extras",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"extras_wrinklemaps\", \"type\": \"capability\", \"group\": \"extras\", \"label\": \"Wrinkle maps\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 68,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "fbx",
            "label": "FBX",
            "type": "target",
            "group": "targets",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"fbx\", \"type\": \"target\", \"group\": \"targets\", \"label\": \"FBX\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 69,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "gltf",
            "label": "gLTF",
            "type": "target",
            "group": "targets",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"gltf\", \"type\": \"target\", \"group\": \"targets\", \"label\": \"gLTF\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 70,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "lod0",
            "label": "LOD0",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"lod0\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD0\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 71,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "lod1",
            "label": "LOD1",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"lod1\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD1\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 72,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "lod2",
            "label": "LOD2",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"lod2\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD2\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 73,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "lod3",
            "label": "LOD3",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"lod3\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD3\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 74,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "lod4",
            "label": "LOD4",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 4,
            "data": "{\"name\": \"lod4\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD4\", \"order\": 4, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 75,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "lod5",
            "label": "LOD5",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 5,
            "data": "{\"name\": \"lod5\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD5\", \"order\": 5, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 76,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "lod6",
            "label": "LOD6",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 6,
            "data": "{\"name\": \"lod6\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD6\", \"order\": 6, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 77,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "lod7",
            "label": "LOD7",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 7,
            "data": "{\"name\": \"lod7\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD7\", \"order\": 7, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 78,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "drest",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 5,
            "data": "{\"name\": \"drest\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 5, \"points\": 0, \"exclude\": [\"unity\", \"webviewer\", \"basic\", \"expressions\", \"visemes\"], \"processing_points\": 0}"
        },
        {
            "id": 79,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "photo",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"photo\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 80,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "iphone",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 4,
            "data": "{\"name\": \"iphone\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 4, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 81,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "tex_1k",
            "label": "1K",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"tex_1k\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"1K\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 82,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "tex_2k",
            "label": "2K",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"tex_2k\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"2K\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 83,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "tex_4k",
            "label": "4K",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"tex_4k\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"4K\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 84,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "no_body",
            "label": "None",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"no_body\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"None\", \"order\": 0, \"points\": 0, \"exclude\": [\"full_body\"], \"processing_points\": 0}"
        },
        {
            "id": 85,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "tex_512",
            "label": "512",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"tex_512\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"512\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 86,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "rig_full",
            "label": "Full B'shapes + Visemes",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"rig_full\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"Full B'shapes + Visemes\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 87,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "rig_none",
            "label": "None",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"rig_none\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"None\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 88,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "full_body",
            "label": "Full Body",
            "type": "capability",
            "group": "head_body",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"full_body\", \"type\": \"capability\", \"group\": \"head_body\", \"label\": \"Full Body\", \"order\": 0, \"points\": 0, \"comment\": \"should be with-body\", \"processing_points\": 0}"
        },
        {
            "id": 89,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "head_only",
            "label": "Head Only",
            "type": "capability",
            "group": "head_body",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"head_only\", \"type\": \"capability\", \"group\": \"head_body\", \"label\": \"Head Only\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 90,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "multishot",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"multishot\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 91,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "pre_sized",
            "label": "Pre Sized",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"pre_sized\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"Pre Sized\", \"order\": 1, \"points\": 0, \"exclude\": [\"head_only\"], \"processing_points\": 0}"
        },
        {
            "id": 92,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "rig_arkit",
            "label": "ARKit B'shapes",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"rig_arkit\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"ARKit B'shapes\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 93,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "photo_depth",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"photo_depth\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 94,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "rig_limited",
            "label": "Limited B'shapes",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"rig_limited\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"Limited B'shapes\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 95,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "measurements",
            "label": "Measurements",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"measurements\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"Measurements\", \"order\": 2, \"points\": 0, \"exclude\": [\"head_only\"], \"processing_points\": 0}"
        },
        {
            "id": 96,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "extras_vellus",
            "label": "Vellus",
            "type": "capability",
            "group": "extras",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"extras_vellus\", \"type\": \"capability\", \"group\": \"extras\", \"label\": \"Vellus\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 97,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "body_from_photo",
            "label": "Photo",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"body_from_photo\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"Photo\", \"order\": 3, \"points\": 0, \"exclude\": [\"head_only\"], \"processing_points\": 0}"
        },
        {
            "id": 99,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_1_5",
            "name": "extras_wrinklemaps",
            "label": "Wrinkle maps",
            "type": "capability",
            "group": "extras",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"extras_wrinklemaps\", \"type\": \"capability\", \"group\": \"extras\", \"label\": \"Wrinkle maps\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 68,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "fbx",
            "label": "FBX",
            "type": "target",
            "group": "targets",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"fbx\", \"type\": \"target\", \"group\": \"targets\", \"label\": \"FBX\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 69,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "gltf",
            "label": "gLTF",
            "type": "target",
            "group": "targets",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"gltf\", \"type\": \"target\", \"group\": \"targets\", \"label\": \"gLTF\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 70,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "lod0",
            "label": "LOD0",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"lod0\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD0\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 71,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "lod1",
            "label": "LOD1",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"lod1\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD1\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 72,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "lod2",
            "label": "LOD2",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"lod2\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD2\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 73,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "lod3",
            "label": "LOD3",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"lod3\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD3\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 74,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "lod4",
            "label": "LOD4",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 4,
            "data": "{\"name\": \"lod4\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD4\", \"order\": 4, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 75,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "lod5",
            "label": "LOD5",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 5,
            "data": "{\"name\": \"lod5\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD5\", \"order\": 5, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 76,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "lod6",
            "label": "LOD6",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 6,
            "data": "{\"name\": \"lod6\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD6\", \"order\": 6, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 77,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "lod7",
            "label": "LOD7",
            "type": "capability",
            "group": "lod",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 7,
            "data": "{\"name\": \"lod7\", \"type\": \"capability\", \"group\": \"lod\", \"label\": \"LOD7\", \"order\": 7, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 78,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "drest",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 5,
            "data": "{\"name\": \"drest\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 5, \"points\": 0, \"exclude\": [\"unity\", \"webviewer\", \"basic\", \"expressions\", \"visemes\"], \"processing_points\": 0}"
        },
        {
            "id": 79,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "photo",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"photo\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 80,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "iphone",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 4,
            "data": "{\"name\": \"iphone\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 4, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 81,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "tex_1k",
            "label": "1K",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"tex_1k\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"1K\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 82,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "tex_2k",
            "label": "2K",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"tex_2k\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"2K\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 83,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "tex_4k",
            "label": "4K",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"tex_4k\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"4K\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 84,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "no_body",
            "label": "None",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"no_body\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"None\", \"order\": 0, \"points\": 0, \"exclude\": [\"full_body\"], \"processing_points\": 0}"
        },
        {
            "id": 85,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "tex_512",
            "label": "512",
            "type": "capability",
            "group": "texture",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"tex_512\", \"type\": \"capability\", \"group\": \"texture\", \"label\": \"512\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 86,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "rig_full",
            "label": "Full B'shapes + Visemes",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"rig_full\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"Full B'shapes + Visemes\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 87,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "rig_none",
            "label": "None",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"rig_none\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"None\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 88,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "full_body",
            "label": "Full Body",
            "type": "capability",
            "group": "head_body",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"full_body\", \"type\": \"capability\", \"group\": \"head_body\", \"label\": \"Full Body\", \"order\": 0, \"points\": 0, \"comment\": \"should be with-body\", \"processing_points\": 0}"
        },
        {
            "id": 89,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "head_only",
            "label": "Head Only",
            "type": "capability",
            "group": "head_body",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"head_only\", \"type\": \"capability\", \"group\": \"head_body\", \"label\": \"Head Only\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 90,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "multishot",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"multishot\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 3, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 91,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "pre_sized",
            "label": "Pre Sized",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"pre_sized\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"Pre Sized\", \"order\": 1, \"points\": 0, \"exclude\": [\"head_only\"], \"processing_points\": 0}"
        },
        {
            "id": 92,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "rig_arkit",
            "label": "ARKit B'shapes",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"rig_arkit\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"ARKit B'shapes\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 93,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "photo_depth",
            "label": null,
            "type": "recipe",
            "group": "recipe",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"photo_depth\", \"type\": \"recipe\", \"group\": \"recipe\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 94,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "rig_limited",
            "label": "Limited B'shapes",
            "type": "capability",
            "group": "rig",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 1,
            "data": "{\"name\": \"rig_limited\", \"type\": \"capability\", \"group\": \"rig\", \"label\": \"Limited B'shapes\", \"order\": 1, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 95,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "measurements",
            "label": "Measurements",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"measurements\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"Measurements\", \"order\": 2, \"points\": 0, \"exclude\": [\"head_only\"], \"processing_points\": 0}"
        },
        {
            "id": 96,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "extras_vellus",
            "label": "Vellus",
            "type": "capability",
            "group": "extras",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 0,
            "data": "{\"name\": \"extras_vellus\", \"type\": \"capability\", \"group\": \"extras\", \"label\": \"Vellus\", \"order\": 0, \"points\": 0, \"processing_points\": 0}"
        },
        {
            "id": 97,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "body_from_photo",
            "label": "Photo",
            "type": "capability",
            "group": "body_gen",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 3,
            "data": "{\"name\": \"body_from_photo\", \"type\": \"capability\", \"group\": \"body_gen\", \"label\": \"Photo\", \"order\": 3, \"points\": 0, \"exclude\": [\"head_only\"], \"processing_points\": 0}"
        },
        {
            "id": 99,
            "pricing_model_id": 5,
            "model_name": "default_v3",
            "pipeline_version": "develop_2",
            "name": "extras_wrinklemaps",
            "label": "Wrinkle maps",
            "type": "capability",
            "group": "extras",
            "model_points": 0.00000,
            "points": null,
            "model_processing_points": null,
            "processing_points": null,
            "description": null,
            "order": 2,
            "data": "{\"name\": \"extras_wrinklemaps\", \"type\": \"capability\", \"group\": \"extras\", \"label\": \"Wrinkle maps\", \"order\": 2, \"points\": 0, \"processing_points\": 0}"
        }
    ]
);