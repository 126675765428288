import { makeStyles } from '@material-ui/styles';
import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';

const baseStyle = {        
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const useStyles = makeStyles(theme => ({
  dropAreaStyles: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  }
}));


export function DGPDemoDropzone(props) {
    const {onDrop} = props;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: 'image/*', onDrop});
  const classes = useStyles();

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div {...getRootProps({style})}>
      <input {...getInputProps()} />
      <div className={classes.dropAreaStyles}>
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <div onClick={(event) => {event.stopPropagation();}}>
        {props.children}
        </div>
    </div>
  );
}