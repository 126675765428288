import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersTable } from './components';
import mockData from './data';
import { getJSON } from 'common/network';
import { ActiveAccounts, CreatedAccountsOverTimeChart } from 'components';
import { Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ROWS_PER_PAGE = 10;

const UserList = () => {
  const classes = useStyles();
  const _mockData = mockData(ROWS_PER_PAGE);

  const [accounts, setAccounts] = useState(_mockData.accounts),
    [totalCount, setTotalCount] = useState(_mockData.count),
    [totalCreatedByDate/*, setTotalCreatedByDate*/] = useState([]),
    [accountsCounters, setAccountsCounters] = useState({ "by_status": 0 }),
    //[loadingAccountsCounters, setLoadingAccountsCounters] = useState(false),
    [currentPage, setCurrentPage] = useState(0),
    [rowsPerPage] = useState(ROWS_PER_PAGE);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [keyWord, setKeyword] = useState("");

  useEffect(() => {

    let url = new URL(process.env.REACT_APP_API_ROOT + "v3/aggregator/accounts"),
      params = { page: currentPage + 1, page_size: rowsPerPage, expand: [/*"TotalCreatedByDate", */"status_counters"], ...keyWord && {email: keyWord} };
    
    console.log('user search url', url);

    Object.keys(params).forEach((key) => {
      if (typeof params[key] === "string" || typeof params[key] === "number") {
        url.searchParams.append(key, params[key]);
      } else {
        params[key].forEach((item) => url.searchParams.append(key, item));
      }
    });

    setLoadingAccounts(true);
    getJSON(url).then(response => {
      const userData = response.accounts.map(user => {
        let rUser = user;
        if (!user.name) {
          rUser.name = "";
        }
        rUser.address = {};

        return rUser;
      })
      setAccounts(userData);
      setTotalCount(response.total_size);
      setAccountsCounters(response.counters);
      /*setTotalCreatedByDate(response.totalCreatedByDate.countersLast30Days);
      
      */
      setLoadingAccounts(false);
    });
  }, [currentPage, rowsPerPage, keyWord]);

  useEffect(() => {
    setCurrentPage(0);
  }, [keyWord]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ActiveAccounts activeAccountsCount={accountsCounters["by_status"]["active"]}
            loading={loadingAccounts} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CreatedAccountsOverTimeChart countersByDate={totalCreatedByDate} loading={loadingAccounts} />
        </Grid>
      </Grid>

      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.content}>
          <UsersTable
            accounts={accounts}
            loadingAccounts={loadingAccounts}
            total={totalCount}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            onChangePage={(page) => setCurrentPage(page)}
            onChangeKeyword={setKeyword}
          />
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default UserList;
