import * as React from "react";
import { Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EditIcon from "@mui/icons-material/Edit";
import { PRIMARY_COLOR_HEX } from "theme/palette";
import { IconButton, Typography } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import { makeStyles } from "@material-ui/core/styles";
import { formatDate } from "helpers/formaters";

/* const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: { backgroundColor: "#8bc34a" },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "white",
        color: "#1b5e20",
      },
    },
  },
}); */

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(4),
  },
  title: {
    // color: 'red'
  },
  addPackageButton: {
    backgroundColor: "#7b42d0", // theme.palette.primary.main,
    color: "#66ee98",
    padding: "8px 16px",
    width: "100%",
  },
  packageContainer: {
    backgroundColor: "#f2f2f6",
    marginTop: "32px",
  },
  divider: {
    height: "4px",
    backgroundColor: theme.palette.primary.main,
  },
  expireTime: {
    color: theme.palette.primary.contrastText,
  },
  expireOn: {
    color: theme.palette.primary.contrastText,
  },
  editButton: {
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
    backgroundColor: theme.palette.primary.contrastText,
    width: "25px",
    height: "25px",
    borderRadius: "50%",
  },
  expiredButton: {
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
    backgroundColor: theme.palette.primary.contrastText,
    width: "25px",
    height: "25px",
    borderRadius: "50%",
  },
  checkButton: {
    backgroundColor: theme.palette.primary.contrastText,
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.primary.main} !important`,
  },
  exitButton: {
    backgroundColor: theme.palette.primary.contrastText,
    width: "25px !important",
    height: "25px !important",
    borderRadius: "50%",
    border: `1px solid ${"#f71128"} !important`,
  },
  notInThisMonthDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#eeeeee",
    margin: "3px",
    boxShadow: "none",
    borderRadius: 0,
    padding: "1px",
  },
  normalDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#e8f5e9",
    margin: "3px",
    boxShadow: "none",
    borderRadius: 0,
    padding: "1px",
    cursor: "pointer",
  },
  selectedDayPaper: {
    width: "31px",
    height: "31px",
    backgroundColor: "#f9fbe7",
    margin: "3px",
    boxShadow: "none",
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "lime",
    padding: "1px",
    cursor: "pointer",
  },
  todayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "lightGreen",
    margin: "3px",
    boxShadow: "none",
    borderRadius: 0,
    padding: "1px",
    cursor: "pointer",
    color: " white",
  },
}));

const DateButton = ({
  date,
  setDate,
  isPickerOpen,
  setIsPickerOpen,
  setConfirm,
  setIsExpiredOpen,
}) => {
  const [value, setValue] = React.useState(date);
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    if (!isOpen) {
      setValue(date);
    }

    setIsOpen((isOpen) => !isOpen);
    setIsPickerOpen(!isOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClickCheck = (event) => {
    setIsOpen((isOpen) => !isOpen);
    setIsPickerOpen(!isOpen);
    setAnchorEl(event.currentTarget);
    setConfirm();
    setDate(value);
  };

  const handleClickDateCancel = (event) => {
    setIsOpen(false);
    setIsPickerOpen(false);
  };

  const handleClickExpiredOpen = (event) => {
    setIsExpiredOpen(true);
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: isOpen ? "white" : PRIMARY_COLOR_HEX,
        padding: "5px 0px 0px 0px",
        margin: "1px 2px 1px 1px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
      }}
      gap={1}
    >
      <Typography
        variant="h4"
        align="left"
        // className={classes.expireTime}
        style={{ color: isOpen ? PRIMARY_COLOR_HEX : "white" }}
      >
        {date ? formatDate(date) : "expiry not set"}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          clearable
          open={isOpen}
          onClose={() => setIsOpen(false)}
          label="Basic example"
          value={value}
          closeOnSelect={false}
          minDate={new Date().setDate(new Date().getDate() + 1)}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          PopperProps={{
            placement: "bottom-end",
            anchorEl: anchorEl,
          }}
          renderInput={(params) => (
            <Stack sx={{ flexDirection: "row" }} gap={1}>
              {isOpen ? (
                <IconButton
                  className={classes.exitButton}
                  onClick={handleClickDateCancel}
                >
                  <ClearIcon sx={{ color: "#f71128" }} />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.expiredButton}
                  onClick={handleClickExpiredOpen}
                >
                  <AvTimerIcon sx={{ color: PRIMARY_COLOR_HEX, zoom: 1.2 }} />
                </IconButton>
              )}
              {isOpen ? (
                <IconButton
                  className={classes.checkButton}
                  id="long-button1"
                  onClick={handleClickCheck}
                >
                  <CheckIcon sx={{ color: PRIMARY_COLOR_HEX }} />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.editButton}
                  onClick={handleClick}
                >
                  <EditIcon sx={{ color: PRIMARY_COLOR_HEX }} />
                </IconButton>
              )}
            </Stack>
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default DateButton;
