import Auth from '@aws-amplify/auth';
import axios from 'axios';

const getJSON = (url, options) => {
    const { useAuth } = Object.assign({}, { useAuth: true }, options);
    let headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };
    if (useAuth) {
        return Auth.currentAuthenticatedUser().then(userData => {
            headers["Authorization"] = `Bearer ${userData.signInUserSession.accessToken.jwtToken}`;
            return fetch(url, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                //credentials: 'same-origin', // include, *same-origin, omit
                headers: headers,
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *client,
                //body: body,
            }).then(response => response.json());
        });
    }
    else {
        return fetch(url).then(response => response.json());
    }
}

const postJSON = (url, body, options) => {
    const { useAuth } = Object.assign({}, { useAuth: true }, options);
    let headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };
    if (useAuth) {
        return Auth.currentAuthenticatedUser().then(userData => {
            headers["Authorization"] = `Bearer ${userData.signInUserSession.accessToken.jwtToken}`;
            return fetch(url, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                //credentials: 'same-origin', // include, *same-origin, omit
                headers: headers,
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *client,
                body: body,
            }).then(response => response.json());
        });
    }
    else {
        return fetch(url).then(response => response.json());
    }
}


// axios instance for making requests 
const axiosInstance = axios.create();

// request interceptor for adding token
axiosInstance.interceptors.request.use(async (config) => {
    var authData = await Auth.currentAuthenticatedUser();
    // add token to request headers
    config.headers['Authorization'] = `Bearer ${authData.signInUserSession.accessToken.jwtToken}`;
    config.paramsSerializer = params => {
        const searchParams = new URLSearchParams();
        Object.keys(params).forEach(key => {
          if (Array.isArray(params[key])) {
            params[key].forEach(element => {
              searchParams.append(key, element)
            });
          }
          else {
            searchParams.append(key, params[key]);
          }
        });
        return searchParams.toString();
      };
    return config;
});

//export default axiosInstance;


export { getJSON, postJSON, axiosInstance };