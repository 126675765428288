import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { SessionStoreProvider } from "common/session";

ReactDOM.render(
  <SessionStoreProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SessionStoreProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
