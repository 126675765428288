import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { axiosInstance } from "common/network";
import { Box, Stack, TextField } from "@mui/material";
import {
  Modal,
  Button,
  IconButton,
  Typography,
  Divider,
  MenuItem,
  Select,
  Grid,
  FormControl,
} from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatNumber, formatDate } from "helpers/formaters";
import { PRIMARY_COLOR_HEX } from "theme/palette";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import EventIcon from "@mui/icons-material/Event";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  title: {
    // color: 'red'
  },
  divider: {
    height: "4px",
    backgroundColor: theme.palette.primary.main,
  },
  plusButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "#66ee98",
    width: "24px",
    height: "24px",
    borderRadius: "50% !important",
    border: `1px solid ${theme.palette.primary.main} !important`,
  },
  calendarButton: {
    width: "32px",
    height: "32px",
  },
  expiredDateModalTitle: {
    paddingTop: "12px",
    paddingBottom: "18px",
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    padding: "8px 8px",
    maxWidth: "220px",
    width: "100%",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "8px 8px",
    maxWidth: "220px",
    width: "100%",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  tierTitle: {
    fontSize: "18px",
    fontWeight: "normal",
    paddingBottom: "4px",
  },
  pointText: {
    // "& .MuiInput-input": {
    //   "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    //     "-webkit-appearance": "none",
    //   },
    // },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  pointContainerError: {
    border: "1px solid red",
  },
  pointValue: {
    fontSize: "18px",
    fontWeight: "normal",
    textAlign: "center",
    height: "36px",
    borderWidth: "0px !important",
  },
  pointValueError: {
    fontSize: "18px",
    fontWeight: "normal",
    color: "red",
    textAlign: "center",
    height: "36px",
  },
  noBorder: {
    border: "none",
  },
  expiredDateText: {
    fontSize: "18px",
    fontWeight: "normal",
    textAlign: "center",
  },
  styleExpiredDate: {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    backgroundColor: theme.palette.primary.contrastText,
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "8px",
    paddingBottom: "6px",
  },
  inputContainer: {
    // display: { sm: "flex", xs: "block" },
    display: "flex",
    // flexDirection: { sm: "row", xs: "column" }
  },
  dropdown: {
    height: "56px",
    backgroundColor: "#f2f2f2",
    paddingLeft: "16px",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "normal",
  },
  dateInput: {
    display: "flex",
    height: "56px",
    backgroundColor: "#f2f2f2",
    paddingLeft: "16px",
    paddingRight: "16px",
    borderRadius: "8px",
    borderWidth: 0,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 0,
  },
}));

const iconStyles = {
  selectIcon: {
    color: PRIMARY_COLOR_HEX,
    marginRight: "8px",
  },
};

const AddPackageModal = (props) => {
  const { accountUuid } = props;
  const [open, setOpen] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [point, setPoint] = useState("");
  const [expiredDate, setExpiredDate] = useState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTier, setSelectedTier] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  const [formError, setFormError] = useState({
    tier: { text: "", value: false },
    point: { text: "", value: false },
    expiredDate: { text: "", value: false },
  });

  const classes = useStyles();

  useEffect(() => {
    fetchDropDownData();
  }, []);

  useEffect(() => {
    const defaultValue = tiers?.find((item) => item?.name === "Advanced")?.uuid;
    setSelectedTier(defaultValue ? defaultValue : "");
  }, [tiers]);

  useEffect(() => {
    setIsValid(selectedTier?.length > 0 && (point > 0 || point === ""));
  }, [selectedTier, point]);

  useEffect(() => {
    if (point > 0 || point === "") {
      setFormError((prevState) => ({
        ...prevState,
        point: { text: "", value: false },
      }));
    } else {
      setFormError((prevState) => ({
        ...prevState,
        point: { text: "must be larger than 0", value: true },
      }));
    }
  }, [point]);

  const fetchDropDownData = () => {
    try {
      axiosInstance
        .get(
          process.env.REACT_APP_API_ROOT +
            `v3/billing/products?active=true&product_type=standard`
        )
        .then((response) => {
          setTiers(response?.data?.length > 0 ? response?.data : []);
        });
    } catch (err) {
      console.log("fetchDropDownData error:", err);
    }
  };

  const onBlurPoint = (e) => {
    setPoint(point);
    if (point > 0 || point === "") {
      setFormError((prevState) => ({
        ...prevState,
        point: { text: "", value: false },
      }));
    } else {
      setFormError((prevState) => ({
        ...prevState,
        point: { text: "must be larger than 0", value: true },
      }));
    }
  };

  const handleClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    setOpen(props.open);

    if (props.open) {
      setPoint("");
      setExpiredDate(undefined);
    }
  }, [props.open]);

  const onChangePoint = (e) => {
    //setPoint(Number(e.target.value));
    setPoint(e.target.value);
  };

  const handleChange = (event) => {
    setSelectedTier(event.target.value);
  };

  const handleAddPackage = () => {
    // requst api https://testing-api.didimo.co/v3/accounts/{accountUuid}/packages
    const body = {
      package_uuid: selectedTier,
      ...(point !== "" && {
        points: point,
      }),
      ...(expiredDate !== undefined && {
        expiry_date: moment(new Date(expiredDate)).format("YYYY-MM-DD"),
      }),
    };

    const url =
      process.env.REACT_APP_API_ROOT + `v3/accounts/${accountUuid}/packages`;
    console.log("API POST: ", url);
    console.log("------- body ------", body);

    try {
      axiosInstance.post(url, body).then((response) => {
        console.log("AddExtraPackage success: ", response);
        props.handleAddPackageSuccess();
      }).catch = (e) => {
        console.log("AddExtraPackage error:", e);
        handleClose();
      };
    } catch (err) {
      console.log("AddExtraPackage error:", err);
      handleClose();
    }
  };

  const handleClickAdd = () => {
    if (point === "") {
      setPoint(10);
    } else {
      const value = Number(point) + 10;
      setPoint(formatNumber(value));
    }
  };

  const handleClickMinus = () => {
    if (point === "") {
      return;
    }
    const value = Number(point) - 10;
    if (value < 0) {
      setPoint("");
    } else {
      setPoint(formatNumber(value));
    }
  };

  const handleClick = (event) => {
    setIsOpen((isOpen) => !isOpen);
    setAnchorEl(event.currentTarget);
  };

  const CustomExpandMore = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => {
      return (
        <ExpandMoreIcon
          {...rest}
          className={clsx(className, classes.selectIcon)}
        />
      );
    }
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      hideBackdrop
    >
      <Stack
        sx={{
          position: "absolute",
          top: "50%",
          left: { lg: "55%", xs: "50%" },
          transform: "translate(-50%, -50%)",
          width: { sm: "70%", xs: "85%" },
          backgroundColor: "white",
          border: "1px solid #00000040",
          boxShadow: 24,
          borderRadius: "2px",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "8px",
          paddingBottom: "6px",
        }}
      >
        <Typography
          id="modal-modal-title"
          className={classes.expiredDateModalTitle}
          variant="h4"
        >
          Add new package
        </Typography>
        <Divider className={classes.divider} />

        <Grid container justify="space-between">
          <Grid item xs={12} sm={5}>
            <FormControl
              fullWidth
              style={{ marginTop: "16px", marginBottom: "0px" }}
            >
              <Typography className={classes.tierTitle}>Tier</Typography>

              <Select
                label=""
                value={selectedTier}
                defaultValue={selectedTier}
                onChange={handleChange}
                displayEmpty
                className={classes.dropdown}
                disableUnderline
                IconComponent={CustomExpandMore}
              >
                <MenuItem
                  disabled
                  value=""
                  style={{
                    height: "42px",
                    fontSize: "18px",
                    fontWeight: "normal",
                  }}
                >
                  <em>Choose desired tier</em>
                </MenuItem>

                {tiers.map((tier) => (
                  <MenuItem
                    key={tier.uuid}
                    value={tier.uuid}
                    style={{
                      height: "42px",
                      fontSize: "18px",
                      fontWeight: "normal",
                    }}
                  >
                    {tier.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl
              fullWidth
              style={{ marginTop: "16px", marginBottom: "0px" }}
            >
              <Typography className={classes.tierTitle}>Points</Typography>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "56px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#f2f2f2",
                  borderRadius: "8px",
                }}
                className={formError.point.value && classes.pointContainerError}
              >
                <IconButton
                  className={classes.plusButton}
                  id="long-button2"
                  onClick={handleClickMinus}
                >
                  <RemoveIcon sx={{ color: PRIMARY_COLOR_HEX }} />
                </IconButton>
                <Box
                  sx={{
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <TextField
                    id="outlined-bare"
                    className={classes.pointText}
                    InputProps={{
                      classes: {
                        input: formError.point.value
                          ? classes.pointValueError
                          : classes.pointValue,
                        notchedOutline: classes.noBorder,
                      },
                    }}
                    variant="outlined"
                    type="number"
                    disableUnderline
                    onChange={onChangePoint}
                    onBlur={onBlurPoint}
                    value={point !== "" ? point.toString() : ""}
                  />
                </Box>
                <IconButton
                  className={classes.plusButton}
                  id="long-button2"
                  onClick={handleClickAdd}
                >
                  <AddIcon sx={{ color: PRIMARY_COLOR_HEX }} />
                </IconButton>
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              fullWidth
              style={{ marginTop: "16px", marginBottom: "0px" }}
            >
              <Typography className={classes.tierTitle}>
              Expiry date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={expiredDate}
                  onChange={(newValue) => {
                    console.log(newValue);
                    setExpiredDate(newValue);
                  }}
                  open={isOpen}
                  onClose={() => setIsOpen(false)}
                  PopperProps={{
                    placement: "bottom-end",
                    anchorEl: anchorEl,
                  }}
                  minDate={
                    new Date(
                      moment()
                        .clone()
                        .add(1, "days")
                    )
                  }
                  renderInput={(params) => (
                    <Box className={classes.dateInput}>
                      <Typography className={classes.expiredDateText}>
                        {expiredDate ? formatDate(expiredDate) : ""}
                      </Typography>
                      <IconButton
                        className={classes.calendarButton}
                        onClick={handleClick}
                      >
                        <EventIcon sx={{ color: PRIMARY_COLOR_HEX }} />
                      </IconButton>
                    </Box>
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            paddingTop: "120px",
            paddingBottom: "40px",
            flexDirection: { sm: "row", xs: "row" },
          }}
          gap={3}
        >
          <Button
            className={classes.cancelButton}
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            style={{
              backgroundColor: !isValid ? "lightgrey" : PRIMARY_COLOR_HEX,
              color: "white",
              padding: "8px 8px",
              maxWidth: "220px",
              width: "100%",
              borderRadius: "8px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
            color="primary"
            onClick={handleAddPackage}
            disabled={!isValid}
          >
            Add Package
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AddPackageModal;
