import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Typography,
  Tooltip,
  Toolbar,
  IconButton,
  Button,
  Popover,
  CardContent,
  CardActions,
  CardHeader,
  Grid,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  /*highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },*/
  title: {
    flex: '1 1 100%',
  },
}));

const TableToolbar = (props) => {
  const { onFilter } = props;
  const classes = useStyles();
  const { numSelected } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'filterMenu' : undefined;
  const [createdFrom, setCreatedFrom] = useState(null);
  const [createdUntil, setCreatedUntil] = useState(null);


  const handleFilterMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
          DGPs
        </Typography>

        <Tooltip title="Filter list">
          <IconButton disabled={true} aria-label="filter list">
            <FilterListIcon onClick={handleFilterMenuClick} />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardHeader title="Filter Criteria" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  margin="normal"
                  id="created-after-date-picker"
                  label="Created from"
                  format={moment.localeData().longDateFormat("L")}
                  value={createdFrom}
                  onChange={(date) => { setCreatedFrom(date); }}
                  size="small"
                  inputVariant="outlined"
                  disableFuture
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  margin="normal"
                  id="created-before-date-picker"
                  label="Created until"
                  format={moment.localeData().longDateFormat("L")}
                  value={createdUntil}
                  onChange={(date) => { setCreatedUntil(date); }}
                  size="small"
                  inputVariant="outlined"
                  disableFuture
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }} />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button onClick={handleFilterMenuClose}>Cancel</Button><Button onClick={() => { onFilter({ createdFrom: createdFrom, createdUntil: createdUntil }); handleFilterMenuClose(); }}>Apply</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};


TableToolbar.propTypes = {
  className: PropTypes.string
};


export default TableToolbar;
