import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { axiosInstance } from 'common/network';
//import mockData from './data';
//import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';
import DGPSections from './components/DGPSections';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

function useIsMountedRef(){
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => isMountedRef.current = false;
  });
  return isMountedRef;
}

const loadDidimos = async (uuid, page=1) => {
  let ddmoResponse = await axiosInstance
    .get(process.env.REACT_APP_API_ROOT + `v3/didimos/demos`, {
        params: {
          dgp: uuid,
          is_public: [true, false],
          page: page,
          page_size: 20
        }
      });

  let didimos = ddmoResponse.data.didimos;
  for (let i = 0; i< didimos.length; i++) {
    let didimo = didimos[i];
    didimo.__admin_portal = {
      front_png: null
    };
  }
  
  if (ddmoResponse.data.__links.next == null) {
    return didimos;
  }
  else {
    return [...didimos, ...(await loadDidimos(uuid, page+1))];
  }
}

const DGPDetails = () => {
  //const _mockData = mockData(25);
  const [dgp, setDgp] = useState({}),
    [didimos, setDidimos] = useState([]);
    //[loadingDgp, setLoadingDgp] = useState(false),
    //[isOpenCrateDidmModal, setIsOpenCrateDidmModal] = useState(false)
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();

  const handleLoadDidimoImage = async (didimo) => {
    let ddmos = [...didimos];
    const didimoPos = ddmos.findIndex((ddm) => ddm.key === didimo.key);

    let image;
      try {
        image = await axiosInstance
          .get(didimo.artifacts.filter(artifact => artifact.name === "front_png")[0].__links.self, {
            headers: {
              "DIDIMO-FOLLOW-URI": "false"
            }
          });
        
        try {
          const img = await axios
            .get(image.data.uri, {
              responseType: 'blob'
            });
          image = URL.createObjectURL(img.data);
        }
        catch(e) {
          image = null;
        }
      }
      catch(e) {
        image = null;
      }

      //didimo.__admin_portal.front_png = /*image ||*/ '/didimo-viewer/default-didimo-thumb.png';



    ddmos[didimoPos].__admin_portal = {
      front_png: image || '/didimo-viewer/default-didimo-thumb.png'
    };



    //ddmos[didimoPos] = {...ddmos[didimoPos], ...{__admin_portal: {front_png: '/didimo-viewer/default-didimo-thumb.png'}}};
    setDidimos(ddmos);
  }

  const handleManageDidimoPublishStatus = (didimo, status) => {
    axiosInstance.post(status ? didimo.__links.publish : didimo.__links.unpublish)
      .then((response) => {
        // 1. Make a shallow copy of the items
        let ddmos = [...didimos];
        // 2. Make a shallow copy of the item you want to mutate
        const didimoPos = ddmos.findIndex((ddm) => ddm.key === didimo.key);
        // 3. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        /*if (response.is_public != ddmos[didimoPos].is_public) {
          ddmos[didimoPos].is_public = response.data.is_public;
        }*/
        ddmos[didimoPos] = {...ddmos[didimoPos], ...response.data};
        //ddmos[didimoPos] = response;
        // 4. Set the state to our new copy
        setDidimos(ddmos);
      });
  }

  const [searchParams] = useSearchParams();
  const { uuid } = useParams();

  useEffect(() => {
    let url = new URL(process.env.REACT_APP_API_ROOT + `dgps/${uuid}`),
      params = {
        /*page: currentPage + 1, page_size: rowsPerPage,
        createdFrom: filterCreatedFrom ? moment(filterCreatedFrom).format("YYYY-MM-DD") : "",
        createdUntil: filterCreatedUntil ? moment(filterCreatedUntil).format("YYYY-MM-DD") : ""*/
      };

    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    //setLoadingDgp(true);
    axiosInstance
      .get(process.env.REACT_APP_API_ROOT + `v3/dgps/${uuid}`, {
          params: params
        })
      .then(response => {
        if(isMountedRef.current){
          setDgp(response.data);
          //setLoadingDgp(false);
        }
      });
    (async () => {
      let ddmos = await loadDidimos(uuid);
      if(isMountedRef.current) {
        /*if (ddmos.filter(ddmo => ddmo.status !== "done" && ddmo.status !== "error").length > 0) {
          setTimeout(checkPendingDidimos, 3000);
        }*/
        setDidimos(ddmos);
      }

    })();

    //getJSON(process.env.REACT_APP_API_ROOT + `v3/didimos/demos?pipeline=${uuid}&is_public=true&is_public=false`)
    // .then(response => setDidimos(response));
  }, [searchParams, isMountedRef, uuid]);

  /*useEffect(() => {
    (async () => {
      let images = {};
      let ddmos = (didimos || []).filter((didimo) => { return fileImages[didimo.key] !== null && didimo.status === "done"; });

      for(var i = 0; i < ddmos.length; i++) {
        let didimo = ddmos[i];
        
        let image = await axiosInstance
          .get(didimo.artifacts.filter(artifact => artifact.name === "front_png")[0].__links.self, {
            headers: {
              "DIDIMO-FOLLOW-URI": "false"
            }
          })
          .then((response) => {
            return response.data.uri
            /*return axios
              .get(response.data.uri, {
                responseType: 'arraybuffer'
              })
              .then((img) => {
                return "data:" + img.headers["Content-Type"] + ";base64,"+Buffer.from(img.data).toString('base64');//"data:" + img.headers["content-type"] + ";base64,"+Buffer.from(img.data).toString('base64');
                //setFileImages({...fileImages, image});
                //return image;
              });*/
          /*})
          .catch(() => {
            return null;
          });

          images[didimo.key] = image || '/didimo-viewer/default-didimo-thumb.png';
          //setFileImages({...fileImages, image});

      };
      
      setFileImages({...fileImages, ...images});
    })();
  }, [didimos]);*/

  return (
    <div className={classes.root}>
      <DGPSections
        dgpUuid={uuid}
        dgpLabel={dgp.label}
        dgpPipelineVersion={dgp.pipeline_version}
        didimos={didimos}
        //didimoImages={fileImages}
        onManageDidimoPublishStatus={handleManageDidimoPublishStatus}
        //onGenerateDidimo={() => { setIsOpenCrateDidmModal(true) }}
        onDidimoRequest={(didimo) => {
          didimo.__admin_portal = { front_png: null};
          setDidimos([...didimos, didimo]);
        }}
        onDidimoDelete={(didimo) => {
          let ddmos = didimos.filter((ddmo) => ddmo.key !== didimo.key);
          setDidimos(ddmos);
        }}
        onLoadDidimoImage={handleLoadDidimoImage}
      />
    </div>
  );
};

export default DGPDetails;
