import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Chart } from "react-chartjs-2";
import { ThemeProvider } from "@material-ui/core/styles";
import validate from "validate.js";

import { chartjs } from "./helpers";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import validators from "./common/validators";
/*import Routes from "./RoutesComponent";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setLoggedIn, setLoggedOut } from "reducers/session";
import Auth from "services/auth";
import { Minimal as MinimalLayout } from "./layouts";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { setLoadingProfile, setProfile } from "reducers/profile";*/
import { getJSON } from "common/network";
import routes from './routes';
import { ACTION_SET_USER, ACTION_USER_LOADED, SessionContext } from "common/session";
import { awsconfig, getUser } from "common/auth";
import { Amplify, Hub } from "aws-amplify";

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

const App = () => {
  Amplify.configure(awsconfig);
  const session = React.useContext(SessionContext);
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => {
            getJSON(process.env.REACT_APP_API_ROOT + "v3/profiles/me").then(response => {
              session.auth.dispatcher({
                type: ACTION_SET_USER, payload: {
                  givenName: response.given_name,
                  familyName: response.family_name,
                  emailAddress: response.email_address,
                  unreadNotifications: response.newNotifications,
                  userGroups: userData.signInUserSession.accessToken.payload['cognito:groups']
                }
              });
              session.auth.dispatcher({
                type: ACTION_USER_LOADED
              });
            });
          });
          break;
        case 'signOut':
          //setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.error('Sign in failure', data);
          break;
        default:
          break;
      }
    });

  }, [session.auth]);
  const contents = useRoutes(routes());

  return (
    <ThemeProvider theme={theme}>
      {contents}
    </ThemeProvider>);
}

export default App;

/*class App extends Component {
  componentDidMount() {
    if (
      this.props.session.isLoggingIn === null ||
      this.props.session.isLoggingIn === undefined
    ) {
      Auth.getCurrentUser()
        .then((userData) => {
          this.props.setLoadingProfile();
          getJSON(process.env.REACT_APP_API_ROOT + "profiles/me").then(response => {
            this.props.setProfile({
              givenName: response.given_name,
              familyName: response.family_name,
              emailAddress: response.email_address,
              unreadNotifications: response.newNotifications,
              userGroups: userData.groups
            });
          });
          this.props.setLoggedIn();
        })
        .catch(() => {
          this.props.setLoggedOut();
        });
    }
  }

  render() {
    if (this.props.session.isLoggingIn !== false) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <MinimalLayout>
              <Backdrop
                className={this.props.classes.backdrop}
                open={this.props.session.isLoggingIn !== false}
              >
                <CircularProgress
                  color="inherit"
                  style={{ display: "block" }}
                />
              </Backdrop>
            </MinimalLayout>
          </BrowserRouter>
        </ThemeProvider>
      );
    } else {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
      );
    }
  }
}

function mapStateToProps(state) {
  return { session: state.session, profile: state.profile };
}
function mapActionsToProps(dispatch) {
  return bindActionCreators(
    {
      setLoggedIn: setLoggedIn,
      setLoggedOut: setLoggedOut,
      setProfile: setProfile,
      setLoadingProfile: setLoadingProfile
    },
    dispatch
  );
}

export default withStyles(styles)(
  connect(mapStateToProps, mapActionsToProps)(App)
);*/
