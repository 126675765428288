import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const styles = () => ({
  root: {
    whiteSpace: "nowrap"
  },
  symbol: {
    color: "grey"
  },
  notAvailable: {
    color: "rgba(0,0,0,0.4)",
    fontStyle: "italic"
  },
});

const Address = (props) => {
  const { value, onEmptyValueElement, component, classes, ...rest } = props;
  const { country_code = "", state_code = "", city = "", zip_code = "", street_line1 = "", street_line2 = "" } = value || {};

  let Component = component || "span";
  if (!(street_line1 || street_line2 || city || state_code || zip_code || country_code)) {
    if (onEmptyValueElement) {
      return onEmptyValueElement;
    }
    else {
      if (onEmptyValueElement === undefined) {
        return <Typography variant="body1" className={classes.notAvailable}>N/A</Typography>;
      }
    }
  }
  return (
    <Component className={classes.root} {...rest}>
      {street_line1 && <p>{street_line1}</p>}
      {street_line2 && <p>{street_line2}</p>}
      {(city || state_code || zip_code) && <p>{city}{(city && (state_code || zip_code)) ? "," : ""} {state_code} {zip_code}</p>}
      {country_code && <p>{country_code}</p>}
    </Component>
  );
};

Address.propTypes = {
  component: PropTypes.string,
  value: PropTypes.any,
  ifNullValue: PropTypes.any,
};

export default withStyles(styles)(Address);
