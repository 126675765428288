import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { axiosInstance } from "common/network";
import { useSearchParams, Link } from "react-router-dom";
import { useParams } from "react-router";
import { Stack } from "@mui/material";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";

import { formatPoints } from "helpers/formaters";
import { PRIMARY_COLOR_HEX } from "theme/palette";
import {
  AddPackageModal,
  DateButton,
  UpdateExpireDateModal,
  ExpiredDateModal,
} from "./components";
import CurrentTier from "components/CurrentTier/CurrentTier";
import GlobalBalance from "components/GlobalBalance/GlobalBalance";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  title: {
    // color: 'red'
  },
  addPackageButton: {
    marginRight: "8px",
    marginTop: "8px",
  },
  packageContainer: {
    backgroundColor: "white",
    marginTop: "28px",
    minHeight: "400px",
  },
  divider: {
    height: "4px",
    backgroundColor: theme.palette.primary.main,
  },
  expireTime: {
    color: theme.palette.primary.contrastText,
  },
  expireOn: {
    color: theme.palette.primary.contrastText,
    height: "18px",
  },
  editButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: "#66ee98",
    width: "25px",
    height: "25px",
    borderRadius: "50%",
  },
  expiredDateModalTitle: {
    paddingTop: "12px",
    paddingBottom: "18px",
  },
  expiredDateModalDescription: {
    paddingTop: "50px",
    paddingBottom: "40px",
    fontSize: "18px",
    fontWeight: "normal",
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    padding: "8px 8px",
    maxWidth: "220px",
    width: "100%",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "8px 8px",
    maxWidth: "220px",
    width: "100%",
    borderRadius: "8px",
    fontSize: "18px",
    fontWeight: "bold",
  },
}));

const UserDetails = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [expiredOpen, setExpiredOpen] = useState(false);
  const handleExpiredOpen = () => setExpiredOpen(true);
  const handleExpiredClose = () => setExpiredOpen(false);

  const [packageUuid, setPackageUuid] = useState("");
  const [openUpdateDate, setOpenUpdateDate] = useState(false);
  const handleUpdateDateOpen = () => setOpenUpdateDate(true);
  const handleUpdateDateClose = () => setOpenUpdateDate(false);

  const [searchParams] = useSearchParams();
  const { uuid } = useParams();
  const [accountData, setAccountData] = useState();
  const [accountInfo, setAccountInfo] = useState();
  const [packageData, setPackageData] = useState();
  const [loadingAccountData, setLoadingAccountData] = useState(false);
  const [loadingAccountInfo, setLoadingAccountInfo] = useState(false);
  const [loadingPackageData, setLoadingPackageData] = useState(false);

  const fetchAccountData = async (uuid) => {
    setLoadingAccountData(true);
    try {
      axiosInstance
        .get(process.env.REACT_APP_API_ROOT + `v3/accounts/${uuid}/status`)
        .then((response) => {
          setAccountData(response?.data);
          setLoadingAccountData(false);
        });
    } catch (err) {
      console.log("fetchAccountStatus:", err);
      setLoadingAccountData(false);
    }
  };

  const fetchAccountInfo = async (uuid) => {
    setLoadingAccountInfo(true);
    try {
      axiosInstance
        .get(process.env.REACT_APP_API_ROOT + `v3/accounts/${uuid}`)
        .then((response) => {
          setAccountInfo(response?.data);
          setLoadingAccountInfo(false);
        });
    } catch (err) {
      console.log("fetchAccountStatus:", err);
      setLoadingAccountInfo(false);
    }
  };

  const fetchPackageData = async (uuid) => {
    setLoadingPackageData(true);
    try {
      axiosInstance
        .get(
          process.env.REACT_APP_API_ROOT +
            `v3/accounts/${uuid}/packages?product_type=standard&status=active`
        )
        .then((response) => {
          setPackageData(response?.data);
          setLoadingPackageData(false);
        });
    } catch (err) {
      console.log("fetchAccountStatus:", err);
      setLoadingPackageData(false);
    }
  };

  useEffect(() => {
    fetchAccountData(uuid);
    fetchPackageData(uuid);
    fetchAccountInfo(uuid);
  }, [searchParams, uuid]);

  const handlePackageReload = () => {
    fetchAccountData(uuid);
    fetchPackageData(uuid);
    fetchAccountInfo(uuid);
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        className={loadingAccountInfo ? "loading" : ""}
      >
        <Link to={`/accounts`}>
          <Typography className={classes.title} variant="h4">
            Accounts
          </Typography>
        </Link>
        <Typography variant="h4">></Typography>
        <Typography>
          {`${accountInfo?.owner_profile?.given_name} ${accountInfo?.owner_profile?.family_name} - ${accountInfo?.owner_profile?.email_address}`}
        </Typography>
      </Stack>

      <Grid container justify="left" spacing={2} style={{ marginTop: 3 }}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <GlobalBalance
            value={accountData?.balance}
            expiringNext={{
              date: accountData?.next_expiration_date,
              balance: accountData?.next_expiration_points,
            }}
            loading={loadingAccountData}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <CurrentTier
            code={accountData?.tier.code}
            name={accountData?.tier?.name}
            loading={loadingAccountData}
          />
        </Grid>
        {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ActiveDidimoProcesses
            activeProcesses={"0"}
            lockedPoints={accountStatus?.running_operations_locks}
            executingActionsCount={accountStatus?.running_operations}
          />
        </Grid> */}
      </Grid>

      <Grid item xs={12}>
        <div class={loadingPackageData ? "loading" : ""}>
          <Card className={classes.packageContainer}>
            <CardHeader
              title="Active packages"
              action={
                <Button
                  sx={{ textTransform: "none" }}
                  className={classes.addPackageButton}
                  variant="outlined"
                  color="primary"
                  onClick={handleOpen}
                  size="small"
                >
                  Add package
                </Button>
              }
            />

            <CardContent style={{ paddingTop: 0 }}>
              {packageData?.packages?.length > 0 ? (
                packageData?.packages?.map((packageItem, index) => (
                  <React.Fragment key={packageItem.product.uuid}>
                    {index > 0 && <Divider className={classes.divider} />}
                    <Grid container style={{ margin: "20px 0 2px 0" }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={8}
                        direction="column"
                        justify="space-between"
                        container
                        style={{
                          flexGrow: 1,
                          width: "auto",
                          marginRight: "15px",
                        }}
                      >
                        <Grid container justify="space-between" item>
                          <Grid item>
                            <Typography
                              variant="h3"
                              component="h2"
                              style={{ color: PRIMARY_COLOR_HEX }}
                            >
                              {packageItem.product.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              align="left"
                              style={{ paddingTop: "8px" }}
                            >
                              {formatPoints(packageItem.balance)} points left
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        style={{
                          marginLeft: "auto",
                          minWidth: "5em",
                          width: "220px",
                          backgroundColor: PRIMARY_COLOR_HEX,
                          borderRadius: "8px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          align="left"
                          className={classes.expireOn}
                        >
                          {packageItem?.expires_at && "package will expire on"}
                        </Typography>

                        <DateButton
                          date={packageItem?.expires_at}
                          setDate={setSelectedDate}
                          setConfirm={() => {
                            handleUpdateDateOpen();
                            setPackageUuid(packageItem?.uuid);
                          }}
                          isPickerOpen={isOpen}
                          setIsPickerOpen={setIsOpen}
                          setIsExpiredOpen={() => {
                            setPackageUuid(packageItem?.uuid);
                            handleExpiredOpen();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
              ) : (
                <Typography
                  variant="h4"
                  component="h2"
                  style={{ color: PRIMARY_COLOR_HEX }}
                >
                  No active package
                </Typography>
              )}
            </CardContent>
          </Card>
        </div>
      </Grid>

      <AddPackageModal
        accountUuid={uuid}
        open={open}
        handleClose={handleClose}
        handleAddPackageSuccess={handlePackageReload}
      />

      <UpdateExpireDateModal
        accountUuid={uuid}
        packageUuid={packageUuid}
        selectedDate={selectedDate}
        open={openUpdateDate}
        handleClose={handleUpdateDateClose}
        handleSuccess={handlePackageReload}
      />

      <ExpiredDateModal
        accountUuid={uuid}
        packageUuid={packageUuid}
        open={expiredOpen}
        handleClose={handleExpiredClose}
        handleExpirePackageSuccess={handlePackageReload}
      />
    </div>
  );
};

export default UserDetails;
