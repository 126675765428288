import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@material-ui/core";

import { getInitials } from "helpers";
import { StatusBullet } from "components";
import UsersToolbar from "../UsersToolbar";
import { useNavigate, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  registrationDate: {
    width: "15rem",
  },
  notAvailable: {
    color: "rgba(0,0,0,0.4)",
    fontStyle: "italic",
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
}));

const statusColors = {
  active: "success",
  pending: "info",
  info_needed: "info",
  deleted: "danger",
};

const accountStatuses = {
  active: "Active",
  info_needed: "Pending information",
  locked: "Locked",
  deleted: "Deleted",
};

const accountPrivacyPolicyStatuses = {
  pending: "Pending",
  complete: "Complete",
};

const UsersTable = (props) => {
  const {
    className,
    loadingAccounts,
    accounts,
    total,
    page,
    rowsPerPage,
    onChangePage,
    onChangeKeyword,
    ...rest
  } = props;

  const classes = useStyles();

  let navigate = useNavigate();

  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const handleSelectAll = (event) => {
    const { users } = props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map((account) => account.uuid);
    } else {
      selectedUsers = [];
    }

    setSelectedAccounts(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedAccounts.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedAccounts, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedAccounts.slice(1));
    } else if (selectedIndex === selectedAccounts.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedAccounts.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedAccounts.slice(0, selectedIndex),
        selectedAccounts.slice(selectedIndex + 1)
      );
    }

    setSelectedAccounts(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    onChangePage(page);
  };

  const handleRowsPerPageChange = (event) => {
    //setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <UsersToolbar
              onFilter={(v) => {
                navigate("/accounts?created_from=2021-11-30");
                console.log(v);
              }}
              onChangeSearch={event => {
                if(event.target.value?.length > 3) {
                  onChangeKeyword(event.target.value);
                } else {
                  onChangeKeyword("");
                }
              }}
            />
            <Table>
              <col />
              <col />
              <col />
              <col />
              <col className={classes.registrationDate} />
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAccounts.length === accounts.length}
                      color="primary"
                      indeterminate={
                        selectedAccounts.length > 0 &&
                        selectedAccounts.length < accounts.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Privacy settings status</TableCell>
                  <TableCell>Registration date</TableCell>
                  <TableCell>Tier</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={loadingAccounts ? "loading" : ""}>
                {accounts.slice(0, rowsPerPage).map((account) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={account.id}
                    selected={selectedAccounts.indexOf(account.uuid) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAccounts.indexOf(account.uuid) !== -1}
                        color="primary"
                        onChange={(event) =>
                          handleSelectOne(event, account.uuid)
                        }
                        value="true"
                      />
                    </TableCell>

                    <TableCell>
                      {account.status !== "active" ? (
                        <Typography variant="body1">{account.uuid}</Typography>
                      ) : (
                        <Link to={`/accounts/${account.uuid}`}>
                          {account.uuid}
                        </Link>
                      )}
                    </TableCell>

                    {/* <TableCell
                      onClick={() => {
                        navigate(`/accounts/${account.uuid}`, {
                          state: account,
                        });
                      }}
                    >
                      {"20" + account.uuid}
                    </TableCell> */}

                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          //src={account.avatarUrl}
                        >
                          {getInitials(account.name || "?")}
                        </Avatar>
                        {(account.name && (
                          <Typography variant="body1">
                            {account.name}
                          </Typography>
                        )) || (
                          <Typography
                            variant="body1"
                            className={classes.notAvailable}
                          >
                            N/A
                          </Typography>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {account.email_address || (
                        <Typography
                          variant="body1"
                          className={classes.notAvailable}
                        >
                          N/A
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className={classes.statusContainer}>
                        <StatusBullet
                          className={classes.status}
                          color={statusColors[account.status]}
                          size="sm"
                        />
                        {accountStatuses[account.status] /*.toLowerCase()*/}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.statusContainer}>
                        <StatusBullet
                          className={classes.status}
                          color={
                            statusColors[
                              { pending: "deleted", complete: "active" }[
                                account.policy_acceptance_status
                              ]
                            ]
                          }
                          size="sm"
                        />
                        {
                          accountPrivacyPolicyStatuses[
                            account.policy_acceptance_status
                          ]
                        }
                      </div>
                    </TableCell>
                    <TableCell>
                      {moment(account.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {account.currentTier ? account.currentTier.name : "N/D"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[50]}
          showFirstButton={true}
          showLastButton={true}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
};

export default UsersTable;
