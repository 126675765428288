import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Toolbar, Tooltip, IconButton, CardActions, Button, Grid, Popover, Card, CardHeader, CardContent } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { SearchInput } from 'components';

/*import { SearchInput } from 'components';*/

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    maxWidth: '300px',
  },
  title: {
    flex: '1 1 100%',
  },
}));

const UsersToolbar = props => {
  const { className, onFilter, onChangeSearch, ...rest } = props,
    [anchorEl, setAnchorEl] = useState(null),
    open = Boolean(anchorEl),
    id = open ? 'filterMenu' : undefined;

  const classes = useStyles();
  const handleFilterMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const [createdFrom, setCreatedFrom] = useState(null),
    [createdUntil, setCreatedUntil] = useState(null);

  const handleFilterMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Toolbar
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
          Accounts
        </Typography>
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon onClick={handleFilterMenuClick} />
          </IconButton>
        </Tooltip>
        {/*<div className={classes.row}>
        <span className={classes.spacer} />
        <Button className={classes.exportButton}>Export</Button>
        <Button
          color="primary"
          variant="contained"
        >
          Add user
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search user"
        />
  </div>*/}
      </Toolbar>
      <SearchInput
          className={classes.searchInput}
          placeholder="Search by email"
          onChange={onChangeSearch}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardHeader title="Filter Criteria" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  margin="normal"
                  id="created-after-date-picker"
                  label="Created from"
                  format={moment.localeData().longDateFormat("L")}
                  value={createdFrom}
                  onChange={(date) => { setCreatedFrom(date); }}
                  size="small"
                  inputVariant="outlined"
                  disableFuture
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  margin="normal"
                  id="created-before-date-picker"
                  label="Created until"
                  format={moment.localeData().longDateFormat("L")}
                  value={createdUntil}
                  onChange={(date) => { setCreatedUntil(date); }}
                  size="small"
                  inputVariant="outlined"
                  disableFuture
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }} />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button onClick={handleFilterMenuClose}>Cancel</Button><Button onClick={() => { onFilter({ createdFrom: createdFrom, createdUntil: createdUntil }); handleFilterMenuClose(); }}>Apply</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
