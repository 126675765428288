import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  Button,
  IconButton
} from '@material-ui/core';

import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  date: {
    width: '15rem'
  },
  notAvailable: {
    color: 'rgba(0,0,0,0.4)',
    fontStyle: 'italic'
  },
  headerElement: {
    whiteSpace: 'nowrap'
  },
  numberElement: {
    textAlign: 'right'
  },
  didimos: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
    columnGap: '1rem',
    rowGap: '1rem',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  didimoCard: {
      position: "relative",
      height: "280px",
    padding: theme.spacing(1),
    color: "black",
    listStyleType: 'none',
    borderRadius: '4px',
    boxShadow: "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
    backgroundColor: "white",
    "& .image-container": {
      maxWidth: "100%",
      paddingTop: "100%",
      position: "relative"
    },
    "& .image-container img": {
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      marginLeft: "auto", 
      marginRight: "auto"
    },
    "& p": {
      textAlign: "center",
      lineHeight: "2rem",
      marginTop: theme.spacing(1)
    },
    /*"& img": {
      maxWidth: "100%"
    }*/
  },
  dgpAttributes: {

  },
  contents: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    border: "1px solid black"
  },
  
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  actions: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
  }
}));

const DidimoCard = props => {
  const { file, didimo, imageReference, onMenuOpen, onLoadDidimoImage, onManageDidimoPublishStatus, onDelete, onUpload, onCancelUpload, ...rest } = props;
  const [uploading, setUploading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
      if (!!didimo && onLoadDidimoImage && !didimo?.__admin_portal.front_png) {
        onLoadDidimoImage(didimo);
      }
  }, [didimo, onLoadDidimoImage]);


  return (
    <li
        key={didimo?.key || file.name}
        {...rest}
        className={classes.didimoCard}
    >
        <div class="image-container">
            {imageReference && <img src={imageReference} alt="Didimo preview" />}
        </div>
        {!!didimo && (
            <>
            <p>{didimo?.key || file.name}</p>
                {/*<Button onClick={() => { onManageDidimoPublishStatus(didimo, !didimo.is_public); }}>{didimo.is_public ? 'unpublish' : 'publish'}</Button>
                <Button onClick={() => { onDelete(didimo); }}>delete</Button>*/}
                <IconButton className={classes.actions}
                    aria-label="more"
                    id="long-button"
                    aria-controls="long-menu"
                    //aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => onMenuOpen(event, didimo)}
                >
                    <MoreVertIcon sx={{ color: red[500] }} />
                </IconButton>
            </>)
        }
        {!didimo && (
            <p>
                <Button disabled={uploading} onClick={() => {setUploading(true); onUpload(file);}}>Upload</Button>
                <Button disabled={uploading} onClick={() => {onCancelUpload(file);}}>Cancel</Button>
            </p>
        )
        }
    </li>
  );
};

DidimoCard.propTypes = {
  className: PropTypes.string,
  dgps: PropTypes.array.isRequired
};

export default DidimoCard;
