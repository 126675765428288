import React from 'react';
import mockData from './data';
import "./ConfigureAndSpend.css"
import {
    Table,
    TableBody,
    TableHead,
} from '@material-ui/core';

function PricingElementEditor(props) {
    const [miniEditorValue, setMiniEditorValue] = React.useState(getInitPoints(props.points, props.processing_points));
    const [expanded, setExpanded] = React.useState(false);
    const [valid, setValid] = React.useState(true);

    function getInitPoints(points, proc_points) {
        let initPoints = points !== null ? points : '';
        if (!!proc_points || proc_points === '0' || proc_points === 0) initPoints += '/' + proc_points;
        return initPoints;
    }

    function mixFieldOnChange(textValue) {
        setMiniEditorValue(textValue);

        const ok = textValue === '' || textValue.match(/^[0-9.]+(\s?\/\s?[0-9.]*)?$/);
        setValid(ok);

        if (ok) {
            const [points, processing_points] = textValue.split(/\s?\/\s?/);
            props.onChange('points', points == null ? '' : points);
            props.onChange('processing_points', processing_points == null ? '' : processing_points);
        }
    }

    function propChangeWithMiniEditor(prop, value) {
        //update miniEditorValue
        let [p, pp] = miniEditorValue.split('/');
        if (prop === 'points') {
            p = value;
        } else if (prop === 'processing_points') {
            pp = value;
        }
        setMiniEditorValue(getInitPoints(p, pp));

        //continue event propagation
        props.onChange(prop, value);
    }

    function doNotCaptureKeyDown(e) {
        e.stopPropagation();
    }

    return <div>
        <div className={"pricing-element-editor-mini" + (valid ? ' valid' : ' invalid') + (expanded ? ' expanded' : '')} >
            <input
                autoFocus
                size='5'
                type='text'
                onChange={e => mixFieldOnChange(e.target.value)}
                value={miniEditorValue}
                placeholder={props.model_points ? '' : '0/0'}
            />
            <button className='expand' onClick={() => setExpanded(!expanded)}>⊞</button>
        </div>
        {
            expanded &&
            <div className="pricing-element-editor" onKeyDown={doNotCaptureKeyDown}>
                <button className="close" onClick={() => setExpanded(false)}>x</button>
                Points:
                <input
                    size='5'
                    type='text'
                    onChange={e => propChangeWithMiniEditor('points', e.target.value)}
                    value={props.points ? props.points : ''}
                    placeholder={props.model_points}
                />
                <button onClick={() => props.onExpand('points')}>◊</button>

                <br />
                Processing Cost:
                <input
                    size='5'
                    type='text'
                    onChange={e => propChangeWithMiniEditor('processing_points', e.target.value)}
                    value={props.processing_points ? props.processing_points : ''}
                    placeholder={props.model_processing_points}
                />
                <button onClick={() => props.onExpand('processing_points')}>◊</button>

                <br />
                Label:
                <input
                    size='20'
                    type='text'
                    onChange={e => props.onChange('label', e.target.value)}
                    value={props.label ? props.label : ''}
                    placeholder='custom label'
                />
                <button onClick={() => props.onExpand('label')}>&lt;=&gt;</button>

                <br /> Type: <input size='20' type='text' readOnly={true} value={props.type ? props.type : ''} />
                <br /> Group: <input size='20' type='text' readOnly={true} value={props.group ? props.group : ''} />
                <br /> Desc: <textarea readOnly={true} value={props.description ? props.description : ''} />

            </div>
        }
    </div>
}

function PricingElementView(props) {

    let classNames = "pricing-element-view";
    if (props.isdefault) { classNames += " is-default" }

    return (
        <div className={classNames}
            onClick={props.onClick}
        >
            {props.points}
            {(props.processing_points != null) &&
                <span>/{props.processing_points}</span>
            }

        </div>
    )
}

class ConfigureAndSpend extends React.Component {
    constructor(props) {
        super(props);

        let data = props.data || mockData();
        data.every(a => a.__index_name = [a.pipeline_version, a.group, a.name].join('|'));

        this.triggerHaveData = function (entries) {
            if (props.onHaveData) {
                props.onHaveData(entries.filter(e => e.__is_dirty));
            }
        }

        this.state = {
            activated: false,
            originalEntries: JSON.parse(JSON.stringify(data)),
            entries: data,
            selectedEntry: null,
            selectedColumns: []
        };
    }

    unique(array) {
        return array.reduce((a, i) => (a.includes(i) ? a : [...a, i]), []);
    }

    dataElement(params) {
        const fqn = (typeof params === typeof []) ? params.join('|') : params;
        return this.state.entries.filter(e => e.__index_name === fqn)[0]
        //return this.state.entries.filter(e=>e.pipeline_version === ver && e.name === name)[0]
    }

    getElementChanges(params) {
        const fqn = (typeof params === typeof []) ? params.join('|') : params;
        const element = this.state.entries.filter(e => e.__index_name === fqn)[0];
        const original = this.state.originalEntries.filter(e => e.__index_name === fqn)[0];

        function equiv(a, b) {
            if (a === b) return true;
            return (a === null || a === '') && (b === null || b === '');
        }

        let changes = [];
        for (let n in original) {
            let _orig = original[n];
            let _elem = element[n];

            if (!equiv(_orig, _elem)) {
                changes.push(n);
            }
        }

        return changes;
    }

    selectElement(e) {
        this.setState({ selectedEntry: e });
    }

    handleChange = (element) => (name, value) => {
        let entries = this.state.entries;
        let entry = entries.filter(e => e === element)[0];
        entry[name] = value;

        entry.__is_dirty = this.getElementChanges(entry.__index_name).length > 0;

        this.setState({ entries: entries });
        this.triggerHaveData(entries);
    }

    handleExpand = (element) => (name, value) => {
        let entries = this.state.entries;
        entries
            .filter(e => e.name === element.name && e[name] !== element[name])
            .forEach(e => {
                e[name] = element[name];
                e.__is_dirty = this.getElementChanges(e.pipeline_version, e.name).length > 0;
            });

        this.setState({ entries: entries });
        this.triggerHaveData(entries);
    }

    handleCopyVersions(versions) {

        let entries = this.state.entries;
        const [_first, ..._others] = versions;
        let origin = entries.filter(e => e.pipeline_version === _first);

        origin.forEach(originElement => {
            entries.forEach(targetElement => {
                if (_others.indexOf(targetElement.pipeline_version) > -1
                    && targetElement.group === originElement.group
                    && targetElement.name === originElement.name) {

                    if (targetElement.label !== originElement.label) {
                        targetElement.label = originElement.label;
                        targetElement.__is_dirty = true;
                    }

                    if (targetElement.description !== originElement.description) {
                        targetElement.description = originElement.description;
                        targetElement.__is_dirty = true;
                    }

                    let origin_p = originElement.points !== null ? originElement.points : originElement.model_points;
                    let target_p = targetElement.points !== null ? targetElement.points : targetElement.model_points;
                    if (target_p !== origin_p || originElement.model_points !== targetElement.model_points) {
                        targetElement.points = origin_p;
                        targetElement.__is_dirty = true;
                    }

                    let origin_pp = originElement.processing_points !== null ? originElement.processing_points : originElement.model_processing_points;
                    let target_pp = targetElement.processing_points !== null ? targetElement.processing_points : targetElement.model_processing_points;
                    if (target_pp !== origin_pp || originElement.processing_points !== targetElement.processing_points) {
                        targetElement.processing_points = origin_pp;
                        targetElement.__is_dirty = true;
                    }

                }
            });
        })

        this.setState({ entries: entries });
    }

    handleFilter(e) {
        this.setState({ filter: e.target.value, selectedColumns: [] });
    }

    handleSave(e) {
        //iterate all dirty and send
    }

    handleReset(e) {
        let entries = JSON.parse(JSON.stringify(this.state.originalEntries));
        this.setState({
            entries: entries,
            selectedEntry: null,
            selectedColumns: []
        });
        this.triggerHaveData(entries);
    }

    handleNavigation = (type) => (e) => {
        if (type === "up") return; //must be used to stop interval runs

        //const filteredData = this.state.entries.filter(e => e.pipeline_version.match(this.state.filter));
        //let f = filteredData.indexOf(this.state.selectedEntry);

        let next = false;
        let nodeIndex = null;
        switch (e.key) {
            case "Tab":
                e.preventDefault();
                next = e.currentTarget.nextElementSibling;
                break;

            case "ArrowRight":
                next = e.currentTarget.nextElementSibling;
                break;

            case "ArrowLeft":
                next = e.currentTarget.previousElementSibling;
                break;

            case "ArrowUp":
                nodeIndex = Array.from(e.currentTarget.parentNode.childNodes).indexOf(e.currentTarget);
                next = e.currentTarget.parentNode.previousElementSibling;
                if (next)
                    next = next.childNodes[nodeIndex];
                break;

            case "ArrowDown":
                nodeIndex = Array.from(e.currentTarget.parentNode.childNodes).indexOf(e.currentTarget);
                next = e.currentTarget.parentNode.nextElementSibling;
                if (next)
                    next = next.childNodes[nodeIndex];
                break;
            default:
                break;
        }

        if (next) {
            next.click();
        }
    }

    handleSelectColumn = (column) => (e) => {

        let cols = this.state.selectedColumns;
        let location = cols.indexOf(column);

        if (location < 0) {
            cols.push(column);
        } else if (location === 0) {
            cols = [];
        } else {
            cols.splice(location, 1);
        }




        /*if(!cols.length || cols.length===1){
          cols=[null,null];
        }
    
        if(cols[0] === null){
          cols=[column,null];
        }else if(cols[0] === column){
          cols = [null,null];
        }else if(cols[1] === null){
          cols[1] = column;
        }
    */
        /*
        if(cols[0] === null){
          cols=[column,null];
        }else if(cols[0] === column){
          cols = [null,null];
        }else if(cols[1] === null){
          cols[1] = column;
        }else if(cols[1] === column){
          cols=[column,null];
        }else if(cols[1] !== column){
          cols=[column,null];
        }else{
          alert('dont know what to do: ' + cols.join(','));
        }
        */

        this.setState({ selectedColumns: cols });
    }


    render() {
        const filteredData = this.state.entries.filter(e => e.pipeline_version.match(this.state.filter));
        const cols_headers = this.unique(filteredData.map(e => e.pipeline_version));
        const rows_headers = this.unique(filteredData.map(e => e.group + '|' + e.name));

        return (
            <div id="princig_model_config">
                <input onChange={e => this.handleFilter(e)} />
                <button onClick={e => this.handleSave(e)}>Save</button>
                <button onClick={e => this.handleReset(e)}>Clear Local Changes</button>

                <button
                    disabled={this.state.selectedColumns.filter(e => e).length < 2}
                    onClick={e => this.handleCopyVersions(this.state.selectedColumns)}>Copy {this.state.selectedColumns[0] ? this.state.selectedColumns[0] : '_________'} -> {this.state.selectedColumns[1] ? this.state.selectedColumns[1] : '_________'}{this.state.selectedColumns[2] ? '(+)' : ''} </button>

                <Table className="pricing_model" cellPadding="0" cellSpacing="0">
                    <TableHead>
                        <tr>
                            <th key='empty1' />
                            <th key='empty2' />
                            {cols_headers.map(c =>
                                <th
                                    className={
                                        this.state.selectedColumns.map((e, i) => e === c ? ('is-selected-' + (i < 2 ? i : 'star')) : undefined).join(' ')
                                    }
                                    key={c} onClick={this.handleSelectColumn(c)}>{c}</th>
                            )}
                        </tr>
                    </TableHead>
                    <TableBody>
                        {rows_headers.map(row => {
                            const [g, r] = row.split('|');

                            return <tr key={row}><th>{g}</th><th>{r}</th>
                                {cols_headers.map(c => {
                                    const element = this.dataElement([c, g, r]);

                                    if (!element) {
                                        let ref;
                                        return <td className='not-defined'
                                            onKeyDown={this.handleNavigation("down")}
                                            onClick={() => { this.selectElement(null); ref.focus() }}>
                                            <div className='pricing-element-editor-mini not-defined'>
                                                <input defaultValue='[No Definition]' readOnly ref={i => ref = i} />
                                            </div>
                                        </td>;
                                    }

                                    const elemKey = c + '@@' + r;


                                    let hasDefaultValues = true;
                                    if (element.points !== null && element.points !== '') {
                                        hasDefaultValues = false;
                                    }
                                    if (element.processing_points !== null && element.processing_points !== '') {
                                        hasDefaultValues = false;
                                    }

                                    return <td key={elemKey}
                                        onClick={() => this.selectElement(element)}
                                        className={element.__is_dirty ? "is-dirty" : undefined}
                                        onKeyDown={this.handleNavigation("down")} onKeyUp={this.handleNavigation("up")}
                                    >{
                                            (this.state.selectedEntry === element) ? <PricingElementEditor
                                                points={element.points}
                                                points_placeholder={element.model_points}
                                                processing_points={element.processing_points}
                                                processing_points_placeholder={element.model_processing_points}
                                                label={element.label}
                                                type={element.type}
                                                group={element.group}
                                                description={element.description}
                                                onChange={this.handleChange(element)}
                                                onExpand={this.handleExpand(element)}

                                            /> : <PricingElementView
                                                isdefault={hasDefaultValues}
                                                points={element.points ? element.points : element.model_points}
                                                processing_points={element.processing_points ? element.processing_points : element.model_processing_points}
                                            />

                                        }</td>
                                })}
                            </tr>
                        }
                        )}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

/*const ConfigureAndSpend = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    md={7}
                    xs={12}
                >
                    <Notifications />
                </Grid>
                <Grid
                    item
                    md={5}
                    xs={12}
                >
                    <Password />
                </Grid>
            </Grid>
        </div>
    );
};*/

export default ConfigureAndSpend;
