import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';

/*import { getInitials } from 'helpers';*/
import Datetime from 'components/DataTypes/Datetime';
import TableToolbar from './TableToolbar';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  date: {
    width: "15rem"
  },
  notAvailable: {
    color: "rgba(0,0,0,0.4)",
    fontStyle: "italic"
  },
  headerElement: {
    whiteSpace: "nowrap"
  },
  numberElement: {
    textAlign: "right"
  }
}));

const QueueConfigTable = props => {
  const { className, loadingQueues, queues, total, page, rowsPerPage, onChangePage, onFilter, ...rest } = props;

  const classes = useStyles();


  const handlePageChange = (event, page) => {
    onChangePage(page);
  };

  const handleRowsPerPageChange = event => {
    //setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <TableToolbar onFilter={onFilter} />
            <Table>
              <colgroup>
                <col />
                <col className={classes.date} />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerElement}>Queue</TableCell>
                  <TableCell className={classes.headerElement}>Pipeline</TableCell>
                  <TableCell className={`${classes.headerElement} ${classes.numberElement}`}>Min instances</TableCell>
                  <TableCell className={`${classes.headerElement} ${classes.numberElement}`}>Max instances</TableCell>
                  <TableCell className={`${classes.headerElement} ${classes.numberElement}`}>Max pipeline instances</TableCell>
                  <TableCell className={classes.headerElement}>Created at</TableCell>
                  <TableCell className={`${classes.headerElement} ${classes.numberElement}`}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={loadingQueues ? "loading" : ""}>
                {queues.slice(0, rowsPerPage).map(queue => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={'queue_' + queue.uuid}
                  >
                    <TableCell>
                      {queue.label}
                    </TableCell>
                    <TableCell>
                      {queue.pipeline_version}
                    </TableCell>
                    <TableCell className={classes.numberElement}>
                      {queue.min_instances}
                    </TableCell>
                    <TableCell className={classes.numberElement}>
                      {queue.max_instances}
                    </TableCell>
                    <TableCell className={classes.numberElement}>
                      {queue.instance_max_pipelines}
                    </TableCell>
                    <TableCell>
                      <Datetime value={queue.created_at} />
                    </TableCell>
                    <TableCell>
                      {queue.is_active ? "Active" : "Inactive"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[50]}
          showFirstButton={true}
          showLastButton={true}
        />
      </CardActions>
    </Card>
  );
};

QueueConfigTable.propTypes = {
  className: PropTypes.string,
  queues: PropTypes.array.isRequired
};

export default QueueConfigTable;
