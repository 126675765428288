import React, {
    createContext,
    useReducer
} from 'react';

export const ACTION_SET_USER = "set_user";
export const ACTION_LOADING_USER_INFORMATION = "loading_user";
export const ACTION_USER_LOADED = "user_loaded";
export const ACTION_USER_LOGOUT = "logout";

export const USER_STATUS_NO_INFO = "no_info";
export const USER_STATUS_LOGGED_OUT = "logged_out";
export const USER_STATUS_LOGGING_IN = "logging_in";
export const USER_STATUS_LOGGED_IN = "logged_in";

//import { CHANGE_DEVELOPER, GET_DATA_AFTER_REFRESH, INITIAL_STATE, SAVE_USER_LOGIN, USER_LOGOUT } from './constants';

const authReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case ACTION_SET_USER:
            return {
                ...state,
                user: payload
            };
        case ACTION_LOADING_USER_INFORMATION:
            return {
                ...state,
                authenticationStatus: USER_STATUS_LOGGING_IN,
                isAuthenticating: true
            }
        case ACTION_USER_LOADED:
            return {
                ...state,
                authenticationStatus: USER_STATUS_LOGGED_IN,
                isAuthenticated: true
            }
        case ACTION_USER_LOGOUT:
            return {
                ...state,
                authenticationStatus: USER_STATUS_LOGGED_OUT,
            };
        /*case SAVE_USER_LOGIN:
            return {
                ...state,
                loading: false,
                user: payload,
                isAuth: true,
            };
        case INITIAL_STATE:
            return payload;
        case CHANGE_DEVELOPER:
            return {
                ...state,
                loading: false,
                isDeveloper: payload.isDeveloper,
                isAuth: true,
            };*/
        default:
            return state;
    }
}

const authInitialState = {
    user: {},
    authenticationStatus: USER_STATUS_NO_INFO,
    isAuthenticated: false,
    isAuthenticating: false
};

const initialState = {
    auth: {
        data: authInitialState,
        dispatcher: null
    }
};

export const SessionContext = createContext(initialState);

export function SessionStoreProvider(props) {
    const [state, dispatch] = useReducer(authReducer, authInitialState);

    const value = {
        auth: {
            data: state,
            dispatcher: dispatch
        }
    };

    return (
        <SessionContext.Provider value={value}>
            {props.children}
        </SessionContext.Provider>
    );
}
