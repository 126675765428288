import * as React from "react";
//import Auth from "services/auth";
import { getJSON } from "./network";
import { Auth } from 'aws-amplify';
import { ACTION_SET_USER, ACTION_USER_LOADED, ACTION_USER_LOGOUT, SessionContext, USER_STATUS_NO_INFO } from "./session";
console.log("process.env.REACT_APP_API_ROOT", process.env.REACT_APP_API_ROOT);
console.log("process.env.REACT_APP_AWS_REGION", process.env.REACT_APP_AWS_REGION);
console.log("process.env.REACT_APP_AWS_USER_POOL_ID", process.env.REACT_APP_AWS_USER_POOL_ID);
console.log("process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID", process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID);
console.log("process.env.REACT_APP_AWS_REDIRECT_SIGNIN", process.env.REACT_APP_AWS_REDIRECT_SIGNIN);
console.log("process.env.REACT_APP_AWS_REDIRECT_SIGNOUT", process.env.REACT_APP_AWS_REDIRECT_SIGNOUT);
export const awsconfig = {
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        //identityPoolRegion: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        /*cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
            path: '/',
        // OPTIONAL - Cookie expiration in days
            expires: 365,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            sameSite: "strict" | "lax",
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        },*/

        // OPTIONAL - customized storage object
        storage: localStorage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        //clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: process.env.REACT_APP_AUTH_DOMAIN,
            scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGNIN,
            redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGNOUT,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
};

export async function getUser() {
    try {
        const userData = await Auth.currentAuthenticatedUser();
        return userData;
    } catch (e) {
        return console.error('Not signed in');
    }
}


const AuthContext = React.createContext();

export function useAuth() {
    //const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const session = React.useContext(SessionContext);
    //const navigate = useNavigate();
    const getAuthenticationStatus = async () => {
        if (session.auth.data.authenticationStatus === USER_STATUS_NO_INFO) {
            await login();
        }
        return session.auth.data.authenticationStatus;
    };
    const login = () => {
        return new Promise((res, rej) => {
            Auth.currentAuthenticatedUser()
                .then((userData) => {
                    getJSON(process.env.REACT_APP_API_ROOT + "v3/profiles/me")
                        .then(response => {
                            session.auth.dispatcher({
                                type: ACTION_SET_USER, payload: {
                                    givenName: response.given_name,
                                    familyName: response.family_name,
                                    emailAddress: response.email_address,
                                    unreadNotifications: response.newNotifications,
                                    userGroups: userData.signInUserSession.accessToken.payload["cognito:groups"]
                                }
                            });
                            session.auth.dispatcher({
                                type: ACTION_USER_LOADED
                            });
                            res(session.auth.data.authenticationStatus);
                        });
                    /*session.auth.dispatcher({
                    type: ACTION_SET_USER, payload: {
                        givenName: "Test",
                        familyName: "Testing",
                        emailAddress: "testing@didimo.co"
                    }
                    });*/
                    //setIsAuthenticated(true);
                })
                .catch(() => {
                    session.auth.dispatcher({
                        type: ACTION_USER_LOGOUT
                    });
                    res(session.auth.data.authenticationStatus);
                    //navigate("/profile/login");
                });
        });
    };

    const getProfile = () => {
        return {
            givenName: session.auth.data.user.givenName,
            familyName: session.auth.data.user.familyName,
            emailAddress: session.auth.data.user.emailAddress,
            userGroups: session.auth.data.user.userGroups
        };
    }

    return {
        isAuthenticated() {
            return session.auth.data.isAuthenticated;
        },
        authenticationStatus: session.auth.data.authenticationStatus,
        getAuthenticationStatus,
        login,
        logout() {
            return new Promise((res) => {
                //setIsAuthenticated(false);
                res();
            });
        },
        getProfile
    };
}
export function AuthProvider({ children }) {
    const auth = useAuth();

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}