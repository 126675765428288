export default (recordNumber) => ({
  invoices: Array.apply(null, Array(recordNumber)).map((_, index) => (
    {
      uuid: index,
      invoice_number: `Loading-${index}`,
      invoiceStatus: 0,
      createdAt: "2020-01-01T00:00:00",
      paidAt: "2020-01-01T00:00:00",
      customerName: "Loading...",
      taxId: null,
      taxPercent: 99.9,
      grossValue: 999.9,
      taxValue: 99.9,
      value: 999.9,
      invoiceAddressInformation: {
        countryCode: "PT",
        countryName: null,
        stateCode: "",
        stateName: null,
        city: "Loading...",
        zipCode: "000000",
        streetLine1: "Loading...",
        streetLine2: "Loading..."
      },
      invoiceTaxInfo: {
        type: "TAX",
        percentage: 99.9,
        description: "Loading...",
        jurisdiction: "OO"
      }
    })),
  count: recordNumber
});