import uuid from 'uuid/v1';

export default (recordNumber) => ({
  accounts: Array.apply(null, Array(recordNumber)).map(_ => (
    {
      uuid: uuid(),
      name: 'Loading...',
      currentTier: {
        name: "Loading..."
      },
      address: {
        countryCode: 'Loading...',
        stateCode: 'Loading...',
        city: 'Logading...',
        streetLine1: 'Loading...',
        streetLine2: 'Loading...'
      },
      owner_profile: {
        created_at: 1555016400000,
      },
      email_address: 'loading@email.load',
      phone: '000-000-0000',
      status: { code: "loading", name: "loading..." }
    }
  )),
  count: recordNumber
});