import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useAuth } from "common/auth";

import { Profile, SidebarNav } from "./components";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const { isAuthenticated, getProfile } = useAuth();

  const { userGroups } = getProfile();

  const pages = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
      shouldShow: function () {
        return isAuthenticated() && userGroups.indexOf("Employees") > -1;
      },
    },
    {
      title: "Accounts",
      href: "/accounts",
      icon: <PeopleIcon />,
      shouldShow: function () {
        return isAuthenticated() && userGroups.indexOf("Administrators") > -1;
      },
    },
    /*{
      title: "Products",
      href: "/products",
      icon: <ShoppingBasketIcon />,
      shouldShow: function() {
        return props.session.isLoggedIn === true;
      },
    },
    {
      title: "Authentication",
      href: "/profile/login",
      icon: <LockOpenIcon />,
      shouldShow: function () {
        return true || props.session.isLoggedIn === false;
      },
    },*/
    {
      title: "Orders",
      href: "/reports/orders",
      icon: <AccountBoxIcon />,
      shouldShow: function () {
        return isAuthenticated() && userGroups.indexOf("Employees") > -1;
      },
    },
    {
      title: "Configure & Spend",
      href: "/conf-and-spend",
      icon: <AccountBoxIcon />,
      shouldShow: function () {
        return isAuthenticated() && userGroups.indexOf("Administrators") > -1;
      },
    },
    {
      title: "DGPs",
      href: "/dgps",
      icon: <AccountBoxIcon />,
      shouldShow: function () {
        return isAuthenticated() && userGroups.indexOf("Administrators") > -1;
      },
    },
    {
      title: "Queues",
      href: "/dgps/queues",
      icon: <AccountBoxIcon />,
      shouldShow: function () {
        return isAuthenticated() && userGroups.indexOf("Administrators") > -1;
      },
    },
    /*{
      title: "Settings",
      href: "/settings",
      icon: <SettingsIcon />,
      shouldShow: function() {
        return props.session.isLoggedIn === true;
      },
    },*/
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};
export default Sidebar;
