import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { QueueConfigTable } from "./components";
import { getJSON } from 'common/network';
import mockData from './data';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
//import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const QueueConfigList = () => {
  const classes = useStyles();
  const _mockData = mockData(25);
  const [queues, setQueues] = useState(_mockData.invoices),
    [totalCount, setTotalCount] = useState(_mockData.count),
    [currentPage, setCurrentPage] = useState(0),
    [rowsPerPage] = useState(25),
    [loadingQueues, setLoadingQueues] = useState(false),
    [filterCreatedFrom, setFilterCreatedFrom] = useState(null),
    [filterCreatedUntil, setFilterCreatedUntil] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let page = parseInt(searchParams.get("page") || "1");
    if (page < 1) {
      page = 1;
    }
    setCurrentPage(page - 1);
    let url = new URL(process.env.REACT_APP_API_ROOT + "v3/queues"),
      params = {
        /*page: currentPage + 1, page_size: rowsPerPage,
        createdFrom: filterCreatedFrom ? moment(filterCreatedFrom).format("YYYY-MM-DD") : "",
        createdUntil: filterCreatedUntil ? moment(filterCreatedUntil).format("YYYY-MM-DD") : ""*/
      };

    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    setLoadingQueues(true);
    getJSON(url).then(response => {
      setQueues(response.queue_configs);
      setTotalCount(/*response.total_size*/10);
      setLoadingQueues(false);
    });
  }, [searchParams, currentPage, rowsPerPage, filterCreatedFrom, filterCreatedUntil]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        <QueueConfigTable
          loadingQueues={loadingQueues}
          queues={queues}
          total={totalCount}
          page={currentPage}
          rowsPerPage={rowsPerPage}
          onFilter={(filters) => {
            setFilterCreatedFrom(filters.createdFrom);
            setFilterCreatedUntil(filters.createdUntil);
          }}
          onChangePage={(page) => {
            setSearchParams({ page: page + 1 });
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default QueueConfigList;
