import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import { USER_STATUS_LOGGED_IN, USER_STATUS_LOGGED_OUT } from "common/session";
import Auth from "@aws-amplify/auth";
import { useAuth } from "common/auth";

const useStyles = makeStyles((theme) => ({
  root: {},
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    width: "207.6px",
    height: "20.4px",
    objectFit: "contain",
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const { getAuthenticationStatus } = useAuth();
  useEffect(() => {
    async function getAuthStatus() {
      if ((await getAuthenticationStatus()) === USER_STATUS_LOGGED_IN) {
        setIsLoggedIn(true);
      }
      if ((await getAuthenticationStatus()) === USER_STATUS_LOGGED_OUT) {
        setIsLoggedIn(false);
      }
    }
    getAuthStatus();
  })

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo.png"
            className={classes.logo}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {isLoggedIn === false && (
            <Button
              href="/profile/login"
              className={classes.signOutButton}
              color="inherit"
            >
              Sign in
            </Button>
          )}
          {isLoggedIn === true && (
            <Button
              href="#"
              className={classes.signOutButton}
              color="inherit"
              onClick={() => {
                Auth.signOut();
              }}
            >
              Sign Out
            </Button>
          )}
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
