import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

export const PRIMARY_COLOR_HEX = "#2A0651";
export const PRIMARY_COLOR_RGB = [42, 6, 81];
export const SECONDARY_COLOR_HEX = "#5bed96";
export const SECONDARY_COLOR_RGB = [92, 237, 150];

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: PRIMARY_COLOR_HEX, //colors.indigo[500],
    light: colors.indigo[100],
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: SECONDARY_COLOR_HEX, //colors.blue["A400"],
    light: colors.blue["A400"],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  progress: {
    colorPrimary: {
      backgroundColor: `rgba(${PRIMARY_COLOR_RGB.join(",")}, 0.3)`,
    },
    barColorPrimary: {
      backgroundColor: `rgb(${PRIMARY_COLOR_RGB.join(",")})`,
    },
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};
