import { USER_STATUS_LOGGED_IN, USER_STATUS_LOGGED_OUT } from "common/session";
import { useEffect, useState } from "react";
const { useAuth } = require("common/auth");
const { useNavigate } = require("react-router");

export function RequireAuth({ children, userGroup }) {
    const [isAuthorized, setIsAuthorized] = useState(null);
    const { getAuthenticationStatus, getProfile } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        async function getAuth() {
            const auth = await getAuthenticationStatus();
            let authorized = auth === USER_STATUS_LOGGED_IN;

            if (userGroup) {
                const { userGroups } = getProfile();
                authorized = authorized && userGroups.indexOf(userGroup) >= 0;
            }

            setIsAuthorized(authorized);
            if (auth === USER_STATUS_LOGGED_OUT) {
                navigate("/profile/login");
            }
        }
        getAuth();
    });

    return isAuthorized === true
        ? children
        : "";
}
