import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Line } from 'react-chartjs-2';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },

  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  chartContainer: {
    position: 'relative',
    width: "100%"
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const ActiveAccounts = props => {
  const { className, countersByDate, loading, ...rest } = props,
        orderedCounterByDate = [...countersByDate].sort((a, b) => {
          return a.date < b.date?-1:1;
        });

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item style={{width: "100%"}}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Accounts created over the last seven days
            </Typography>
            <div class={loading?"loading":""}>
            <div className={classes.chartContainer}>
              <Line
                data={{
                  datasets: [
                    {
                      data: orderedCounterByDate.filter((item, index) => orderedCounterByDate.length - index < 31).map((item) => item.count),
                      legend: {display: false},
                      /*backgroundColor: [
                        theme.palette.primary.main,
                        theme.palette.error.main,
                        theme.palette.warning.main
                      ],
                      borderWidth: 8,
                      borderColor: theme.palette.white,
                      hoverBorderColor: theme.palette.white*/
                    }
                  ],
                  labels: orderedCounterByDate.filter((item, index) => orderedCounterByDate.length - index < 31).map((item) => item.date),
                }}
                height={null}
                width={null}
                options={{
                  borderColor: "red",//theme.palette.primary.main,
                  aspectRatio: 3,
                  legend: {
                    display: false
                  },
                  maintainAspectRatio: false,
                  layout: {
                    padding: {
                        left: 5,
                        right: 5,
                        top: 5,
                        bottom: 0
                    }
                },
                  scales: {
                    xAxes: [{
                      ticks: {
                        display: false
                    },
                        gridLines: {
                            display:false,
                            drawBorder: false,
                            // drawOnChartArea: false
                        }
                    }],
                    yAxes: [{
                      ticks: {
                        display: false,
                        min: 0,
                    },
                        gridLines: {
                          display:false,drawBorder: false,
                            // drawOnChartArea: false
                        }
                    }]
                  }
                }}
                /*ref={graphRef}*/
              />
            </div>
            </div>
          </Grid>
        </Grid>
        
      </CardContent>
    </Card>
  );
};

ActiveAccounts.propTypes = {
  className: PropTypes.string
};

export default ActiveAccounts;
