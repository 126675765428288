import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  TextField
} from '@material-ui/core';

import { axiosInstance } from 'common/network';
import { useCallback } from 'react';
import { DGPDemoDropzone } from './DGPDemoDropzone';
import DidimoCard from './DidimoCard';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  date: {
    width: '15rem'
  },
  notAvailable: {
    color: 'rgba(0,0,0,0.4)',
    fontStyle: 'italic'
  },
  headerElement: {
    whiteSpace: 'nowrap'
  },
  numberElement: {
    textAlign: 'right'
  },
  didimos: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
    columnGap: '1rem',
    rowGap: '1rem',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  didimoCard: {
    padding: theme.spacing(1),
    listStyleType: 'none',
    borderRadius: '4px',
    boxShadow: "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
    "& .image-container": {
      maxWidth: "100%",
      position: "relative"
    },
    "& .image-container img": {
      width: "100%",
      paddingTop: "100%"
    }
  },
  dgpAttributes: {

  },
  contents: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    border: "1px solid black"
  },
  
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  },
  
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%'
  }
}));

const DGPSections = props => {
  const {
    className, loadingDgps, dgpUuid, dgpLabel, dgpPipelineVersion, didimos, total, page, rowsPerPage, didimoImages,
    onLoadDidimoImage, onGenerateDidimo, onDidimoRequest, onManageDidimoPublishStatus, onDidimoDelete, onChangePage,
    onFilter, ...rest } = props;

  const classes = useStyles();
  const [ddmos, setDdmos] = useState([]);
  const [files, setFiles] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const [menuDidimo, setMenuDidimo] = useState(null);

  const handleOnDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      //setIsDragOver(false);

      //let allSelectedCodes = selectedCodes;

      //validateGenerateButton(allSelectedCodes);
    },
    [/*selectedCodes*/]
  );

  const handleCancelUpload = (file) => {
    let filesCopy = [...files];
    let index = filesCopy.indexOf(file);
    if (index !== -1) {
      filesCopy.splice(index, 1);
      setFiles(filesCopy);
    }
  }

  const thumbs = files.map(file => {
    console.log(file);
    return (
      <DidimoCard
        file={file}
        imageReference={file.preview}
        onUpload={(file) => {
          let formData = new FormData();
          formData.append('photo', file);
          formData.append('input_type', 'photo');
          formData.append('transfer_formats', 'gltf');
          formData.append('transfer_formats', 'fbx');
          formData.append('oculus_lipsync', 'true');
          formData.append('aws_polly', 'true');
          formData.append('arkit', 'true');
          formData.append('simple_poses', 'true');
          formData.append('max_texture_dimension', '2048');
          formData.append('is_demo_didimo', 'true');
    
          axiosInstance.post(process.env.REACT_APP_API_ROOT + `v3/didimos`, formData, {
            'headers': {
              'Content-Type': 'multipart/form-data',
              'Didimo-DGP': dgpUuid
            }
          })
            .then((didimo) => {
              onDidimoRequest(didimo.data);
              handleCancelUpload(file);
            });
        }}
        onCancelUpload={handleCancelUpload}
      />
    )
  });

  const handleDelete = (didimo) => {
    axiosInstance.delete(didimo.__links.self)
      .then(() => { onDidimoDelete(didimo) });
  }

  useEffect(() => {
    console.log(didimos);
    let sortedDdmos = [].concat(didimos || []).sort((a, b) => b.is_public === true && a.is_public === false?1:-1);
    setDdmos(sortedDdmos);
    console.log(didimos);
  }, [didimos]);

  const handleMenuOpen = (event, didimo) => {
    setAnchorEl(event.currentTarget);
    setMenuDidimo(didimo);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuDidimo(null);
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Toolbar
              className={classes.root}
            >
              <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
                DGP '{dgpLabel}'
              </Typography>
            </Toolbar>
            <div className={classes.contents}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField id="pipeline-version" label="Pipeline version" variant="outlined" value={dgpPipelineVersion||''} />
              </Box>
              <Box>
              <Typography variant="h3" component="h2">
                Demo didimos
                </Typography>
                <div>
                  <h3>Public</h3>
                  <ul className={classes.didimos}>
                    {ddmos.filter((ddmo) => ddmo.is_public === true).map((didimo) => {
                      return (
                        <DidimoCard
                          didimo={didimo}
                          imageReference={didimo.__admin_portal.front_png}
                          onManageDidimoPublishStatus={() => { onManageDidimoPublishStatus(didimo, !didimo.is_public); }}
                          onDelete={() => {handleDelete(didimo)}}
                          onLoadDidimoImage={onLoadDidimoImage}
                          onMenuOpen={handleMenuOpen}
                        />
                      )
                    })}
                  </ul>
                  <h3>Non-public</h3>
                  <DGPDemoDropzone onDrop={handleOnDrop}>
                    <ul className={classes.didimos}>
                      {thumbs}
                      {ddmos.filter((ddmo) => ddmo.is_public === false).map((didimo) => {
                        return (
                          <DidimoCard
                            didimo={didimo}
                            imageReference={didimo.__admin_portal.front_png}
                            onManageDidimoPublishStatus={() => { onManageDidimoPublishStatus(didimo, !didimo.is_public); }}
                            onDelete={() => {handleDelete(didimo)}}
                            onLoadDidimoImage={onLoadDidimoImage}
                            onMenuOpen={handleMenuOpen}
                          />
                        )
                      })}
                    </ul>
                  </DGPDemoDropzone>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {(!menuDidimo?.is_public && <MenuItem disabled={menuDidimo?.status !== "done"} onClick={() => {handleMenuClose(); onManageDidimoPublishStatus(menuDidimo, !menuDidimo.is_public)}}>Publish</MenuItem>)}
                  {(menuDidimo?.is_public && <MenuItem onClick={() => {handleMenuClose(); onManageDidimoPublishStatus(menuDidimo, !menuDidimo.is_public)}}>Unpublish</MenuItem>)}
                  <MenuItem onClick={() => {let confirmed = window.confirm("Are you sure you want to remove this didimo?"); handleMenuClose(); if (confirmed) { handleDelete(menuDidimo);}}}>Delete</MenuItem>
                </Menu>
              </Box>
            </div>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

DGPSections.propTypes = {
  className: PropTypes.string,
  dgps: PropTypes.array.isRequired
};

export default DGPSections;
