import uuid from 'uuid/v1';

export default [
  {
    id: uuid(),
    invoiceNumber: 'CDD1049',
    value: 30.5,
    customerName: 'Ekaterina Tankova',
    createdAt: 1555016400000,
    status: 'pending'
  },
  {
    id: uuid(),
    invoiceNumber: 'CDD1048',
    value: 25.1,
    customerName: 'Cao Yu',
    createdAt: 1555016400000,
    status: 'delivered'
  },
  {
    id: uuid(),
    invoiceNumber: 'CDD1047',
    value: 10.99,
    customerName: 'Alexa Richardson',
    createdAt: 1554930000000,
    status: 'invoiceNumberunded'
  },
  {
    id: uuid(),
    invoiceNumber: 'CDD1046',
    value: 96.43,
    customerName: 'Anje Keizer',
    createdAt: 1554757200000,
    status: 'pending'
  },
  {
    id: uuid(),
    invoiceNumber: 'CDD1045',
    value: 32.54,
    customerName: 'Clarke Gillebert',
    createdAt: 1554670800000,
    status: 'delivered'
  },
  {
    id: uuid(),
    invoiceNumber: 'CDD1044',
    value: 16.76,
    customerName: 'Adam Denisov',
    createdAt: 1554670800000,
    status: 'delivered'
  }
];
