import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { getJSON } from "common/network";

import {
  ActivePoints,
  CurrentlyExecutingActions,
  TotalProfit,
  ActiveTiers,
  LatestOrders
} from './components';
import { ActiveAccounts } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles(),
    [accountsCounters, setAccountsCounters] = useState({ "by_status": 0 }),
    [loadingAccountsCounters, setLoadingAccountsCounters] = useState(false),
    [orders, setOrders] = useState({}),
    [ordersTotalValue, setOrdersTotalValue] = useState({}),
    [loadingOrders, setLoadingOrders] = useState(false);

  useEffect(() => {
    let accountsUrl = new URL(process.env.REACT_APP_API_ROOT + "v3/aggregator/accounts"),
      accountsParams = { page: 1, page_size: 0, expand: [/*"TotalCreatedByDate",*/ "status_counters"] };
    /*let accountsUrl = new URL(appConfig.apiUris.getAccountList),
      accountsParams = { page: 1, count: 0, expand: ["Counters"] };*/

    Object.keys(accountsParams).forEach((key) => {
      if (typeof accountsParams[key] === "string" || typeof accountsParams[key] === "number") {
        accountsUrl.searchParams.append(key, accountsParams[key]);
      } else {
        accountsParams[key].forEach((item) => accountsUrl.searchParams.append(key, item));
      }
    });

    setLoadingAccountsCounters(true);
    getJSON(accountsUrl).then(response => {
      setAccountsCounters(response.counters);
      setLoadingAccountsCounters(false);
    });


    let url = new URL(process.env.REACT_APP_API_ROOT + "v3/aggregator/orders"),
      params = {
        page: 1, count: 7/*, "expand": ["totalValue"]*/
      };

    Object.keys(params).forEach((key) => {
      if (typeof params[key] === "string" || typeof params[key] === "number") {
        url.searchParams.append(key, params[key]);
      } else {
        params[key].forEach((item) => url.searchParams.append(key, item));
      }
    });

    setLoadingOrders(true);
    getJSON(url).then((response) => {
      setOrders(response.orders);
      setOrdersTotalValue(response.total_count)
      setLoadingOrders(false);
    });
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ActivePoints />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ActiveAccounts
            activeAccountsCount={accountsCounters["by_status"]["active"]}
            loading={loadingAccountsCounters} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CurrentlyExecutingActions />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalProfit value={ordersTotalValue} loading={loadingOrders} />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestOrders orders={orders} loading={loadingOrders} />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <ActiveTiers tierCounters={accountsCounters.by_tiers} loading={loadingAccountsCounters} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
