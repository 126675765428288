import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar
} from '@material-ui/core';
import { ReactComponent as GearsIcon } from "./gears.svg";
import { formatNumber } from 'helpers/formaters';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: "#fff", // theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const CurrentlyExecutingActions = props => {
  const { className, ...rest } = props,
        [currentlyExecuting, setCurrentlyExecuting] = useState(0),
        [loadingCurrentlyExecuting, setLoadingCurrentlyExecuting] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setLoadingCurrentlyExecuting(true);
    // TODO: Replace with a call
    setCurrentlyExecuting(0);
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Currently executing
            </Typography>
            <div class={loadingCurrentlyExecuting?"loading":""}>
              <Typography variant="h3">{formatNumber(currentlyExecuting)}</Typography>
            </div>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <GearsIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CurrentlyExecutingActions.propTypes = {
  className: PropTypes.string
};

export default CurrentlyExecutingActions;
